import styled from "styled-components";

export const CurrencyInputContainer = styled.div`
  .currencyIput {
    width: 83.466vw;
    height: 13.333vw;
    border-radius: 2.666vw;
    border: solid 1px ${(props) => props.borderColor || "#C7C7C7"};
    margin-bottom: 4vw;
    padding-left: 4vw;
    font-size: 4vw;
    outline: none;
  }
`;

const InputText = styled.input.attrs((props) => ({
  type: `${props.type}`,
  placeholder: `${props.placeholder}`,

}))`
  width: ${(props) => props.width || "89.333vw"};
  height: ${(props) => props.height || "13.333vw"};
  border-radius: ${(props) => props.radius || "2.666vw"};
  border: solid 1px ${(props) => props.borderColor || "#C7C7C7"};
  margin-bottom: ${(props) => props.marginBottom || "4vw"};
  padding-left: ${(props) => props.paddingLeft || "9.6vw"};
  align-items: ${(props) => props.align};
  margin-top: ${(props) => props.marginTop};
  font-size: 4vw;
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justify};
  text-align: ${(props) => props.textAlign};
  min-height: ${(props) => props.minHeight};
  background-color: ${(props) => props.backColor};
  color: ${(props) => props.colorTextInput};
  outline: none;
  padding-bottom: ${(props) => props.padBottom};
  z-index: ${(props) => props.zIndex};

  ::placeholder {
    font-family: "Libre Franklin", sans-serif;
    color: ${(props) => props.colorPlace || "#CCCCCC"};
    font-size: ${(props) => props.fontSizePlace || "3.733vw"};
    font-weight: ${(props) => props.fontWeightPlace};
  }

  &:focus {
    border: 2px solid black;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: ${(props) => props.disabledOpacity || '0.5'};
  }
`;

export default InputText;
