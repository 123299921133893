import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
// import PersonalColoring from "./pages/PersonalColoring";
// import Login from "./pages/Login";
// import SignUp from "./pages/SignUp";
// import PassRecover from "./pages/PassRecover";
// import PassRedefinition from "./pages/PassRedefinition";
// import OpenCamera from "./pages/OpenCamera";
// import FinalizeRegistration from "./pages/FinalizeRegistration";
// import Closet from "./pages/Closet";
// import ProfileForm from "./pages/ProfileForm";
// import MyClothes from "./pages/MyClothes";
// import UserLooks from "./pages/UserLooks";
// import api from "./Api";
// import PageLookAlbum from "./pages/PageLookAlbum";
// import SeeMyLook from "./pages/SeeMyLook";
// import Home from "./pages/Home";
// import PageToSeeClothes from "./pages/PageToSeeClothes";
// import ConsultantPanel from "./pages/ConsultantPages/ConsultantPanel";
// import ConsultantCreateLook from "./pages/ConsultantPages/ConsultantCreateLook";
// import CustomerTop20 from "./pages/ConsultantPages/CustomerTop20";
// import ConsultantAddNewLook from "./pages/ConsultantPages/ConsultantAddNewLook";
// import PlanSubscription from "./pages/PlanSubscription";
// import CreateUserLooks from "./pages/CreateUserLooks";
// import EditUserLooks from "./pages/EditUserLooks";
// import Entrance from "./pages/Entrance";
// import ProfileMenu from "./pages/ProfileMenu";
// import ConsultantScheduling from "./pages/ConsultantPages/ConsultantScheduling";
// import Checkout from "./pages/CheckoutPages/Checkout";
// import Top10Dashboard from "./pages/DashboardPages/Top10Dashboard";
// import ColorsOfCloset from "./pages/DashboardPages/ColorsOfCloset";
// import LoginConsultant from "./pages/LoginConsultant";
// import SignUpConsultant from "./pages/SignUpConsultant";
// import CheckoutOnline from "./pages/CheckoutOnline";
// import ConsultantDossiePanel from "./pages/ConsultantPages/ConsultantDossiePanel";
// import PersonalColoringDossie from "./pages/ConsultantPages/PersonalColoringDossie";
// import Mural from "./pages/Mural";
// import ConsultantMural from "./pages/ConsultantPages/Mural";
// import DossieSeason from "./pages/ConsultantPages/DossieSeason";
// import DossieBiotype from "./pages/ConsultantPages/DossieBiotype";
// import CreateLookByConsultant from "./pages/ConsultantPages/CreateUserLookByConsultant";
// import DossieStyle from "./pages/ConsultantPages/DossieStyle";
// import PageToSeeCustomerFoldersLooks from "./pages/ConsultantPages/PageToSeeCustomerFolders";
// import PageToSeeCustomerLooks from "./pages/ConsultantPages/PageToSeeCustomerLooks";
// import ProfileConsultantForm from "./pages/ProfileConsultantForm";
// import PageConsultantLookAlbum from "./pages/PageConsultantLookAlbum";
// import ChromaticCirle from "./pages/ChromaticCircle";
// import FinalizeConsultantRegistration from "./pages/FinalizeConsultantRegistrations";
// import AllColorChart from "./pages/AllColorChart";
// import ProfileEdit from "./pages/UserProfileEdit/ProfileEdit";
// import ColorChartEdit from "./pages/UserProfileEdit/ColorChartEdit";
// import PredominantStyleEdit from "./pages/UserProfileEdit/PredominantStyleEdit";
// import BiotypeEdit from "./pages/UserProfileEdit/BiotypeEdit";
// import MeasurementsEdit from "./pages/UserProfileEdit/MeasurementsEdit";
// import DossieDowload from "./pages/UserProfileEdit/DossieDownload";
// import MenuSandwich from "./pages/MenuSandwich";
// import MyPlanEdit from "./pages/UserProfileEdit/MyPlanEdit";
// import AboutResolva from "./pages/UserProfileEdit/AboutResolva";
// import TermsAndConditions from "./pages/UserProfileEdit/TermsAndConditions";
// import MyConsultant from "./pages/UserProfileEdit/MyConsultant";
// import CheckoutSubscriptionPlan from "./pages/checkoutSubscriptionPlan";
// import CalendarPage from "./pages/CalendarPage";
// import Dashboard from "./pages/Dashboard";
// import ChosenColorChart from "./pages/AllColorChart/Components/ChosenColorChart";
// import EditOrDeleteOutfitCalendar from "./pages/EditOrDeleteOutfitCalendar";
// import ConsultantCalendarAppointment from "./pages/ConsultantPages/consultantCalendarAppoitments";
// import ConsultantDossiesViewer from "./pages/ConsultantPages/ConsultantDossiesViewer";
// import MyTravels from "./pages/PlannedSuitcase/MyTravels";
// import BiotypeEdiConsultant from "./pages/ConsultantPages/ConsultantProfileEdit/BiotypeEditConsultant";
// import ColorChartEditConsultant from "./pages/ConsultantPages/ConsultantProfileEdit/ColorChartEditConsultant";
// import ProfileEditConsultant from "./pages/ConsultantPages/ConsultantProfileEdit/ProfileEditConsultant";
// import PlanEditConsultant from "./pages/ConsultantPages/ConsultantProfileEdit/PlanEditConsultant";
// import AboutResolvaConsultant from "./pages/ConsultantPages/ConsultantProfileEdit/AboutResolvaConsultant";
// import ConsultantTerms from "./pages/ConsultantPages/ConsultantProfileEdit/ConsultantTerms";
// import StyleEditConsultant from "./pages/ConsultantPages/ConsultantProfileEdit/StyleEditConsultant";
// import PassRecoverConsultant from "./pages/passRecoverConsultant";
import PassRedefinitionConsultant from "./pages/passRedefinitionConsultant";
import MobileAddApp from "./desktop/MobileAddApp";
// import ProfileMenuConsultant from "./pages/ConsultantPages/ProfileMenuConsultant";
// import Coupons from "./pages/Coupons";
// import ConsultantDashboard from "./pages/ConsultantPages/ConsultantDashboard";
// import BalanceRelease from "./pages/ConsultantPages/ConsultantDashboard/components/BalanceRelease";
// import ConsultantProfile from "./pages/CheckoutOnline/Components/ConsultantProfile";
// import SelectProfilePictureConsultant from "./pages/ConsultantPages/SelectProfilePictureConsultant";
// import SelectProfilePicture from "./pages/SelectProfilePicture";
// import PassResetNewApp from "./pages/PassResetNewApp";
// import MobileAddApp from "./desktop/MobileAddApp";

// import NotFound from './pages/NotFound';

//Lembrar de validar o token depois
// function PrivateRoute({ children, redirectTo, keyName }) {
//   const [isAuthenticated, setIsAuthenticated] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem(keyName);

//     if (token) {
//       api.get("/validate-token", { headers: { token } }).then((result) => {
//         if (result.data.message === "token inválido") setIsAuthenticated(false);
//       });
//     } else {
//       setIsAuthenticated(false);
//     }
//   }, []);

//   return isAuthenticated ? children : <Navigate to={redirectTo} />;
// }

export default function AppRoutes() {
  return (
    <Routes>
      {/* <Route
        path="/"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <Closet />
          </PrivateRoute>
        }
      />
      <Route path="/entrada" element={<Entrance />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login-consultora" element={<LoginConsultant />} />
      <Route path="/registre-se" element={<SignUp />} />
      <Route path="/registro-consultora" element={<SignUpConsultant />} />
      <Route path="/recuperacao-de-senha" element={<PassRecover />} />
      <Route
        path="/redefinicao-de-senha/:token"
        element={<PassRedefinition />}
      />
      <Route path="/coloracao-pessoal" element={<PersonalColoring />} />
      <Route
        path="/closet-menu"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <Closet />
          </PrivateRoute>
        }
      /> */}

      {/* Esta rota não está sendo chamada */}
      {/* <Route
        path="/finalizar-cadastro"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <FinalizeRegistration />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile-form"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <ProfileForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/minhas-roupas"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <MyClothes />
          </PrivateRoute>
        }
      />
      <Route
        path="/meus-looks"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <UserLooks />
          </PrivateRoute>
        }
      />
      <Route path="/meus-albuns-looks/:id/" element={<PageLookAlbum />} />
      <Route path="/ver-look/:id" element={<SeeMyLook />} />
      <Route path="/home" element={<Home />} />
      <Route
        path="/ver-roupa/:index"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <PageToSeeClothes />
          </PrivateRoute>
        }
      />
      <Route
        path="/painel-da-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <ConsultantPanel />
          </PrivateRoute>
        }
      />
      <Route
        path="/painel-do-agendamento"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <ConsultantCalendarAppointment />
          </PrivateRoute>
        }
      />
      <Route
        path="/calendario"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <CalendarPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/consultora-selecionar-look"
        element={<ConsultantCreateLook />}
      />
      <Route path="/top-20-cliente/:id" element={<CustomerTop20 />} />
      <Route
        path="/consultora-adicionando-look"
        element={<ConsultantAddNewLook />}
      />
      <Route
        path="/consultora-agendamentos"
        element={<ConsultantScheduling />}
      />
      <Route
        path="/contratar-plano"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <PlanSubscription />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route path="/criando-look/:id" element={<CreateUserLooks />} />
      <Route path="/editando-look/:id" element={<EditUserLooks />} />
      <Route path="/abrir-camera/:path" element={<OpenCamera />} />
      <Route
        path="/menu-perfil"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <ProfileMenu />
          </PrivateRoute>
        }
      /> */}

      {/* Esta rota não está sendo chamada */}
      {/* <Route path="/checkout" element={<Checkout />} />
      <Route path="/top10" element={<Top10Dashboard />} />
      <Route path="/cores-do-closet" element={<ColorsOfCloset />} />
      <Route path="/servicos-online" element={<CheckoutOnline />} />
      <Route
        path="/dossie-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <ConsultantDossiePanel />
          </PrivateRoute>
        }
      />
      <Route
        path="/dossie-coloracao-pessoal"
        element={<PersonalColoringDossie />}
      />
      <Route
        path="/mural"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <Mural />
          </PrivateRoute>
        }
      />
      <Route
        path="/mural-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <ConsultantMural />
          </PrivateRoute>
        }
      />
      <Route path="/dossie-estacao/:season" element={<DossieSeason />} />
      <Route path="/dossie-biotipo" element={<DossieBiotype />} />
      <Route path="/dossie-estilo" element={<DossieStyle />} />
      <Route path="/ver-perfil" element={<ConsultantProfile />} />
      <Route
        path="/look-criado-pela-consultora"
        element={<CreateLookByConsultant />}
      />
      <Route
        path="/albums-do-cliente"
        element={<PageToSeeCustomerFoldersLooks />}
      />
      <Route
        path="/looks-do-album-cliente/:id"
        element={<PageToSeeCustomerLooks />}
      />
      <Route
        path="/profile-form-consultora"
        element={<ProfileConsultantForm />}
      />
      <Route path="/finalizando-cadastro" element={<FinalizeRegistration />} />
      <Route
        path="/finalizando-cadastro-consultora"
        element={<FinalizeConsultantRegistration />}
      />
      <Route
        path="/looks-feitos-pela-consultora/:id"
        element={<PageConsultantLookAlbum />}
      />
      <Route
        path="/circulo-cromatico"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <ChromaticCirle />
          </PrivateRoute>
        }
      />
      <Route
        path="/cartela-de-cores"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <AllColorChart />
          </PrivateRoute>
        }
      />
      <Route
        path="/cartela-escolhida/:colorChart"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <ChosenColorChart />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-perfil"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <ProfileEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-cartela-de-cores"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <ColorChartEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-estilo-predominante"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <PredominantStyleEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-biotipo"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <BiotypeEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-medidas"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <MeasurementsEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/meu-plano"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <MyPlanEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/sobre-resolva"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <AboutResolva />
          </PrivateRoute>
        }
      />
      <Route
        path="/minha-consultora"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <MyConsultant />
          </PrivateRoute>
        }
      />
      <Route
        path="/termos-e-condicoes"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <TermsAndConditions />
          </PrivateRoute>
        }
      />
      <Route
        path="/baixar-dossies"
        element={
          <PrivateRoute redirectTo="/login" keyName="resolvaUserToken">
            <DossieDowload />
          </PrivateRoute>
        }
      />
      <Route path="/menu-sandwich" element={<MenuSandwich />} />
      <Route path="/visualizar-dossie" element={<ConsultantDossiesViewer />} />
      <Route
        path="/checkout-assinatura"
        element={<CheckoutSubscriptionPlan />}
      />
      <Route
        path="/editar-ou-deletar-look"
        element={<EditOrDeleteOutfitCalendar />}
      /> */}
      {/* <Route path="*" component={ NotFound } /> */}
      {/* <Route path="/minhas-viagens" element={<MyTravels />} />
      <Route
        path="/editar-perfil-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <ProfileEditConsultant />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-cartela-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <ColorChartEditConsultant />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-estilo-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <StyleEditConsultant />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-biotipo-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <BiotypeEdiConsultant />
          </PrivateRoute>
        }
      />
      <Route
        path="/meu-plano-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <PlanEditConsultant />
          </PrivateRoute>
        }
      />
      <Route
        path="/sobre-resolva-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <AboutResolvaConsultant />
          </PrivateRoute>
        }
      />
      <Route
        path="/termos-e-condicoes-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <ConsultantTerms />
          </PrivateRoute>
        }
      />
      <Route
        path="/consultora/recuperacao-de-senha"
        element={<PassRecoverConsultant />}
      /> */}
      <Route path="/" element={<MobileAddApp />} />
      <Route
        path="/consultora/redefinicao-de-senha/:token"
        element={<PassRedefinitionConsultant />}
      />
      {/* <Route
        path="/menu-perfil-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <ProfileMenuConsultant />
          </PrivateRoute>
        }
      />
      <Route path="/cupom" element={<Coupons />} />
      <Route
        path="/dashboard-consultora"
        element={
          <PrivateRoute
            redirectTo="/login-consultora"
            keyName="resolvaConsultantToken"
          >
            <ConsultantDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/liberacao-de-saldo/:releaseType"
        element={<BalanceRelease />}
      />
      <Route
        path="/selecionar-foto-perfil/:lastPath"
        element={<SelectProfilePicture />}
      />
      <Route
        path="/selecionar-foto-perfil-consultora/:lastPath"
        element={<SelectProfilePictureConsultant />}
      />
      <Route path="redefinicao-senha/:token" element={<PassResetNewApp />} /> */}
      {/* <Route path="/stress-test" element={<StressTest />} /> */}
      {/* <Route path="baixe-o-app-resolva" element={<MobileAddApp />} /> */}
    </Routes>
  );
}
