import styled from 'styled-components';

const RegisterTitle = styled.h1`
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  font-size: 5.866vw;
  padding-top: 4.106vw;
  background-color: transparent;
`;

export default RegisterTitle;