import styled from "styled-components";

export const ContainerLandingPage = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  overflow-x: hidden;
`;

export const ContainerImage = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  background-color: ${(props) => props.colorBg};
  position: relative;
`;

export const ImageFirstStrip = styled.img`
  width: 1920px;
  height: 1079px;
`;

export const ButtonGoToApp = styled.a`
  width: 150px;
  height: 50px;
  border-style: none;
  position: absolute;
  background-color: transparent;
  margin-top: ${(props) => props.topMargin};
  margin-right: ${(props) => props.rightMargin};
`;

export const ImageSecondStrip = styled.img`
  width: 1920vw;
  height: 441px;
`;

export const ImageThirdStrip = styled.img`
  width: 1920vw;
  height: 609px;
`;

export const ImageFourthStrip = styled.img`
  width: 1920vw;
  height: 785px;
`;

export const ImageFifthStrip = styled.img`
  width: 1920vw;
  height: 500px;
`;

export const ImageSixthStrip = styled.img`
  width: 1920vw;
  height: 536px;
`;

export const ImageSeventhStrip = styled.img`
  width: 1920vw;
  height: 500px;
`;

export const ImageEighthStrip = styled.img`
  width: 1920vw;
  height: 788px;
`;

export const ImageFooter = styled.img`
  width: 1920vw;
  height: 181px;
`;
