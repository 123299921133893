import styled from 'styled-components';

export const GeneralContainer = styled.div`
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  /* overflow-y: ${(props) => props.overflowY ? "auto" : "hidden"}; */
  overflow-y: ${(props) => props.overflowY || 'auto'};
  overflow-x: ${(props) => props.overflowX || 'hidden'};
  display: flex;
  top: ${(props) => props.top};
  flex: none;
  flex-direction: column;
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justify};
  font-family: 'Libre Franklin', sans-serif;
  position: ${(props) => props.position};
  z-index: ${(props) => props.containerIndex};
  touch-action: ${(props) => props.touchAction};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backColor};
  /* padding-bottom: 15vh; */
`;

export const WrapperButton = styled.div`
  align-self: flex-start;
  margin: 0 0 8.533vw 0;
  /* padding: 0 5vw; */
`

export const WrapperLooks = styled.div`
  display: flex;
  width: 100vw;
  padding: ${(props) => props.padding || '0 0 0 5vw'};
  min-height: ${(props) => props.minHeight || '38.4vw'};
  height: auto;
  overflow-x: auto;
  flex-direction: row;
  gap: ${(props) => props.gap};

  &::-webkit-scrollbar {
    margin-top: 2vh;
  }

  /* div {
    display: flex;
    flex-direction: column;
  } */

  input {
    padding: 5px;
    border: none;
  }

  
`
export const WrapperLooksModal = styled.div`
  display: flex;
  gap: 0.5rem;
  width: ${(props) => props.width || "100vw"};
  flex-wrap: ${(props) => props.wrap || 'wrap'};
  margin: ${(props) => props.margin || '2vh 0'};
  padding: ${(props) => props.padding || '0 5vw'};
  min-height: ${(props) => props.minHeight || '22vh'};
  overflow-x: auto;
  justify-content: ${(props) => props.justify};
  overflow-y: ${(props) => props.overflowY};
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 700;
  font-size: 5vw;

  &::-webkit-scrollbar {
    margin-top: 2vh;
  }

  /* div {
    display: flex;
    flex: none;
    flex-direction: column;
    position: relative;
  } */

  input {
    padding: 5px;
    display: flex;
    flex: none;
    width: 5.333vw;
    height: 5.333vw;
    /* border: none; */
    position: absolute;
    top: 27.893vw;
    left: 15.36vw;
    border-radius: 100vw;
    z-index: 10000;
    border-style: solid;
    border-top-left-radius: 10px;
  }

  .looks-card {
    flex: none;
    flex-direction: row;
    /* height: 100%; */
  }
`
