import styled from 'styled-components';

const InputLabel = styled.label`
  font-family: 'Libre Franklin', sans-serif;
  font-weight: ${(props) => props.fontWeight || '400'};
  font-size: ${(props) => props.fontSize || '3.3vw'};
  padding-bottom: ${(props) => props.bottom || '1.6vw'};
  background-color: ${(props) => props.bg};
  width: ${(props) => props.width};
  margin-left: ${(props) => props.marginLeftMobile};
  margin-top: ${(props) => props.marginTopInput};
`;

export default InputLabel;