import styled from 'styled-components';

const RegisterBoxFirstStrip = styled.div`
  display: flex;
  justify-content: space-between;
  width: 93vw;
  align-items: center;
  padding: 4.506vw 4.506vw ${(props) => props.bottom} 4.506vw;
  background-color: transparent;
`;

export default RegisterBoxFirstStrip;