import axios from "axios";

require("dotenv").config();

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;

export const adminApi = axios.create({
  baseURL: ADMIN_BASE_URL,
});

export default axios.create({
  baseURL: BASE_URL,
});
