import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FirstStripLoginText from "../../Components/FirstStripLoginText/FirstStripLoginText";
import RegisterContainer from "../../Components/RegisterContainer/RegisterContainer";
import RegisterBox from "../../Components/RegisterBox/RegisterBox";
import RegisterBoxFirstStrip from "../../Components/RegisterBoxFirstStrip/RegisterBoxFirstStrip";
import RegisterBoxFirstStripLeft from "../../Components/RegisterBoxFirstStripLeft/RegisterBoxFirstStripLeft";
import SafeRegisterIcon from "../../assets/images/register-images/safe-register.svg";
import SafeRegisterText from "../../Components/SafeRegisterText/SafeRegisterText";
import ResolvaLogo from "../../assets/images/register-images/resolva-logo.svg";
import RegisterTitle from "../../Components/RegisterTitle/RegisterTitle";
import RegisterText from "../../Components/RegisterText/RegisterText";
import InputTextContainer from "../../Components/InputTextContainer/InputTextContainer";
import InputLabel from "../../Components/InputLabel/InputLabel";
import InputText from "../../Components/InputText/InputText";
import Icon from "../../Components/InputTextIcon/InputTextIcon";
import PadlockIcon from "../../assets/images/login-images/padlock-icon.svg";
import EmailIcon from "../../assets/images/login-images/email-icon.svg";
import Button from "../../Components/Button/style";
import api from "../../Api";
import ResolvaIcon from "../../Components/ResolvaIcon/style";
import { GeneralContainer } from "../../Components/GeneralContainer/style";
import { GeneralFirstStrip } from "../../Components/GeneralFirstStrip/style";
import loadingGif from "../../assets/gifs/loading.gif";
import { StyleLoadingGif } from "../../Components/PixMethod/style";
import { InputTextMessage } from "./style";
import closeEyeIcon from "../../assets/images/close-eye-icon.svg";
import openEyeIcon from "../../assets/images/open-eye-icon.svg";
import xIconSVG from "../../assets/images/xIcon.svg";
import { XIcon } from "../../Components/XIcon/style";

export default function PassRedefinitionConsultant() {
  const [redefinitionObj, setRedefinitionObj] = useState({
    display: "hidden",
    message: "empty",
    color: "#FAFAFA",
  });
  const [passwordObj, setPasswordObj] = useState({
    password: "",
    confirmPass: "",
  });
  const [consultantInfo, setConsultantInfo] = useState({
    name: "",
    username: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [typePassword, setTypePassword] = useState("password");
  const [passRedefined, setPassRedefined] = useState(false);

  const navigate = useNavigate();

  const { token } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const result = await api.get(`/consultant/info/${token}`);
        setConsultantInfo(result.data);
      } catch (e) {
        alert("Erro ao carregar dados, favor refazer o processo!");
        window.location.href =
          "https://resolva-consultant-app.vercel.app/recuperacao-de-senha";
      }
    })();
  }, []);

  function handleChange({ target }) {
    const { name, value } = target;

    setPasswordObj({
      ...passwordObj,
      [name]: value,
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    if (passwordObj.password === passwordObj.confirmPass) {
      try {
        const result = await api.post(`/consultant/update-password/${token}`, {
          newPassword: passwordObj.password,
        });

        if (result.data === "senha atualizada") {
          setRedefinitionObj({
            display: "block",
            message: "Senha atualizada com sucesso!",
            color: "green",
          });
        } else {
          alert("Erro ao atualizar a senha, favor refazer o processo!");
          navigate("/consultora/recuperacao-de-senha");
        }
      } catch (e) {
        alert("Erro ao atualizar a senha, favor refazer o processo!");
        navigate("/consultora/recuperacao-de-senha");
      }
      setPassRedefined(true);
      setIsLoading(false);

      return;
    }
    setIsLoading(false);
    setRedefinitionObj({
      display: "block",
      message: "As senhas devem ser idênticas!",
      color: "red",
    });
    setTimeout(() => {
      setRedefinitionObj({
        display: "hidden",
        message: "empty",
        color: "#FAFAFA",
      });
    }, 4000);
  }

  const showPassword = () => {
    if (typePassword === "text") {
      setTypePassword("password");
    } else {
      setTypePassword("text");
    }
  };

  return (
    <GeneralContainer>
      <GeneralFirstStrip>
        <FirstStripLoginText></FirstStripLoginText>
      </GeneralFirstStrip>
      <RegisterContainer onSubmit={handleSubmit}>
        <RegisterBox height="189.866vw">
          <RegisterBoxFirstStrip bottom="12.986vw"></RegisterBoxFirstStrip>
          <ResolvaIcon
            src={ResolvaLogo}
            alt="Ícone do Resolva Meu Look"
            width="13.146vw"
            height="18.122vw"
          />
          <RegisterTitle>
            OLÁ, {consultantInfo.name.toUpperCase()}
          </RegisterTitle>
          <RegisterText width="59.2vw" bottom="6.4vw">
            Defina uma nova senha para o app Resolva. Depois de criar a sua
            senha volte para o app e faça login.
          </RegisterText>
          <InputTextContainer bgColor="transparent">
            <InputLabel bg="transparent" htmlFor="emailInput">
              E-mail
            </InputLabel>
            <Icon
              src={EmailIcon}
              alt="Icone de email"
              margin="0 0 0 3.466vw"
              width="4.266vw"
              height="3.2vw"
            />
            <InputText
              width="83.733vw"
              id="emailInput"
              value={consultantInfo.username}
              name="username"
              marginBottom="5.066vw"
              borderColor="#000000"
              colorPlace="#000000"
              readOnly
            />
          </InputTextContainer>
          <InputTextContainer bgColor="#FAFAFA">
            <InputLabel bg="#FAFAFA" htmlFor="newPass">
              Nova senha
            </InputLabel>
            <Icon
              src={PadlockIcon}
              alt="Icone de cadeado"
              margin="0 0 0 3.733vw"
              width="3.733vw"
              height="4.266vw"
            />
            <InputText
              width="83.733vw"
              type={typePassword}
              id="passInput"
              placeholder="**********"
              name="password"
              required
              value={passwordObj.password}
              onChange={handleChange}
              borderColor={passwordObj.password !== "" && "black"}
              marginBottom="5.066vw"
            />
            <Icon
              src={typePassword === "text" ? openEyeIcon : closeEyeIcon}
              alt="olho fechado"
              width="4.733vw"
              height="5.266vw"
              onClick={showPassword}
              top="9.5vw"
              right="5vw"
            />
          </InputTextContainer>
          <InputTextContainer bgColor="#FAFAFA">
            <InputLabel bg="#FAFAFA" htmlFor="confirmPass">
              Confirme a nova senha
            </InputLabel>
            <Icon
              src={PadlockIcon}
              alt="Icone de cadeado"
              margin="0 0 0 3.733vw"
              width="3.733vw"
              height="4.266vw"
              top="9.5vw"
            />
            <InputText
              width="83.733vw"
              type={typePassword}
              id="passInput"
              placeholder="**********"
              name="confirmPass"
              required
              value={passwordObj.confirmPass}
              borderColor={passwordObj.confirmPass !== "" && "black"}
              onChange={handleChange}
              marginBottom="1vw"
            />
          </InputTextContainer>
          <InputTextMessage
            display={redefinitionObj.display}
            color={redefinitionObj.color}
          >
            {redefinitionObj.message}
          </InputTextMessage>
          <Button
            fontSize="4.266vw"
            top="1vw"
            width="84vw"
            height="13.333vw"
            bgColor="#000000"
            color="#FFFFFF"
            type="submit"
            disabled={isLoading || passRedefined}
          >
            {isLoading && !passRedefined && (
              <StyleLoadingGif
                bg="#efefef"
                src={loadingGif}
                width="10vw"
                height="10vw"
              />
            )}
            {!isLoading && "FINALIZAR"}
          </Button>
        </RegisterBox>
      </RegisterContainer>
    </GeneralContainer>
  );
}
