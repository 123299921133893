import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import AddAppDesktop from "./desktop/AddAppDesktop";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <BrowserRouter>
    {window.screen.width > 600 ? <AddAppDesktop /> : <App />}
  </BrowserRouter>,
  document.getElementById("root")
);

// serviceWorker.register();
