export const consultantFormDataInitialValue = {
  day: {
    inputName: "Dia do nascimento",
    inputValue: ""
  },
  month: {
    inputName: "Mês do nascimento",
    inputValue: ""
  },
  year: {
    inputName: "Ano do nascimento",
    inputValue: ""
  },
  colorChartId: {
    inputName: "Cartela de cores",
    inputValue: ""
  },
  phone: {
    inputName: "Celular",
    inputValue: ""
  },
  biotypeId: {
    inputName: "Biotipo",
    inputValue: ""
  },
  primaryStyleId: {
    inputName: "Estilo",
    inputValue: ""
  },
  signId: {
    inputName: "Signo",
    inputValue: ""
  },
  bankCode: {
    inputName: "Código do banco",
    inputValue: ""
  },
  agency: {
    inputName: "Agência",
    inputValue: ""
  },
  account: {
    inputName: "Conta",
    inputValue: ""
  },
  accountDv: {
    inputName: "Dígito da conta",
    inputValue: ""
  },
  documentNumber: {
    inputName: "Número do documento",
    inputValue: ""
  },
  legalName: {
    inputName: "Razão social",
    inputValue: ""
  },
  agencyDv: {
    inputName: "Dígito da agência",
    inputValue: ""
  },
  accountType: {
    inputName: "Tipo da conta",
    inputValue: ""
  }
}