import LipstickBrightSpring from "../assets/images/all-color-chart-icons/tone-bright-spring/lipstick-bright-spring.png";
import PencilBrightSpring from "../assets/images/all-color-chart-icons/tone-bright-spring/pencil-bright-spring.png";
import BlushBrightSpring from "../assets/images/all-color-chart-icons/tone-bright-spring/blush-bright-spring.png";
import ShadowBrightSpring from "../assets/images/all-color-chart-icons/tone-bright-spring/shadow-bright-spring.png";
import HairBrightSpring from "../assets/images/all-color-chart-icons/tone-bright-spring/hair-bright-spring.png";
import JewelryBrightSpring from "../assets/images/all-color-chart-icons/tone-bright-spring/jewelry-bright-spring.png";

import LipstickClearSpring from "../assets/images/all-color-chart-icons/tone-clear-spring/lipstick-clear-spring.png";
import PencilClearSpring from "../assets/images/all-color-chart-icons/tone-clear-spring/pencil-clear-spring.png";
import BlushClearSpring from "../assets/images/all-color-chart-icons/tone-clear-spring/blush-clear-spring.png";
import ShadowClearSpring from "../assets/images/all-color-chart-icons/tone-clear-spring/shadow-clear-spring.png";
import HairClearSpring from "../assets/images/all-color-chart-icons/tone-clear-spring/hair-clear-spring.png";
import JewelryClearSpring from "../assets/images/all-color-chart-icons/tone-clear-spring/jewelry-clear-spring.png";

import LipstickHotSpring from "../assets/images/all-color-chart-icons/tone-hot-spring/lipstick-hot-spring.png";
import PencilHotSpring from "../assets/images/all-color-chart-icons/tone-hot-spring/pencil-hot-spring.png";
import BlushHotSpring from "../assets/images/all-color-chart-icons/tone-hot-spring/blush-hot-spring.png";
import ShadowHotSpring from "../assets/images/all-color-chart-icons/tone-hot-spring/shadow-hot-spring.png";
import HairHotSpring from "../assets/images/all-color-chart-icons/tone-hot-spring/hair-hot-spring.png";
import JewelryHotSpring from "../assets/images/all-color-chart-icons/tone-hot-spring/jewelry-hot-spring.png";

import LipstickColdSummer from "../assets/images/all-color-chart-icons/tone-cold-summer/lipstick-cold-summer.png";
import PencilColdSummer from "../assets/images/all-color-chart-icons/tone-cold-summer/pencil-cold-summer.png";
import BlushColdSummer from "../assets/images/all-color-chart-icons/tone-cold-summer/blush-cold-summer.png";
import ShadowColdSummer from "../assets/images/all-color-chart-icons/tone-cold-summer/shadow-cold-summer.png";
import HairColdSummer from "../assets/images/all-color-chart-icons/tone-cold-summer/hair-cold-summer.png";
import JewelryColdSummer from "../assets/images/all-color-chart-icons/tone-cold-summer/jewelry-cold-summer.png";

import LipstickClearSummer from "../assets/images/all-color-chart-icons/tone-clear-summer/lipstick-clear-summer.png";
import PencilClearSummer from "../assets/images/all-color-chart-icons/tone-clear-summer/pencil-clear-summer.png";
import BlushClearSummer from "../assets/images/all-color-chart-icons/tone-clear-summer/blush-clear-summer.png";
import ShadowClearSummer from "../assets/images/all-color-chart-icons/tone-clear-summer/shadow-clear-summer.png";
import HairClearSummer from "../assets/images/all-color-chart-icons/tone-clear-summer/hair-clear-summer.png";
import JewelryClearSummer from "../assets/images/all-color-chart-icons/tone-clear-summer/jewelry-clear-summer.png";

import LipstickSoftSummer from "../assets/images/all-color-chart-icons/tone-soft-summer/lipstick-soft-summer.png";
import PencilSoftSummer from "../assets/images/all-color-chart-icons/tone-soft-summer/pencil-soft-summer.png";
import BlushSoftSummer from "../assets/images/all-color-chart-icons/tone-soft-summer/blush-soft-summer.png";
import ShadowSoftSummer from "../assets/images/all-color-chart-icons/tone-soft-summer/shadow-soft-summer.png";
import HairSoftSummer from "../assets/images/all-color-chart-icons/tone-soft-summer/hair-soft-summer.png";
import JewelrySoftSummer from "../assets/images/all-color-chart-icons/tone-soft-summer/jewelry-soft-summer.png";

import LipstickDarkAutumn from "../assets/images/all-color-chart-icons/tone-dark-autumn/lipstick-dark-autumn.png";
import PencilDarkAutumn from "../assets/images/all-color-chart-icons/tone-dark-autumn/pencil-dark-autumn.png";
import BlushDarkAutumn from "../assets/images/all-color-chart-icons/tone-dark-autumn/blush-dark-autumn.png";
import ShadowDarkAutumn from "../assets/images/all-color-chart-icons/tone-dark-autumn/shadow-dark-autumn.png";
import HairDarkAutumn from "../assets/images/all-color-chart-icons/tone-dark-autumn/hair-dark-autumn.png";
import JewelryDarkAutumn from "../assets/images/all-color-chart-icons/tone-dark-autumn/jewelry-dark-autumn.png";

import LipstickSoftAutumn from "../assets/images/all-color-chart-icons/tone-soft-autumn/lipstick-soft-autumn.png";
import PencilSoftAutumn from "../assets/images/all-color-chart-icons/tone-soft-autumn/pencil-soft-autumn.png";
import BlushSoftAutumn from "../assets/images/all-color-chart-icons/tone-soft-autumn/blush-soft-autumn.png";
import ShadowSoftAutumn from "../assets/images/all-color-chart-icons/tone-soft-autumn/shadow-soft-autumn.png";
import HairSoftAutumn from "../assets/images/all-color-chart-icons/tone-soft-autumn/hair-soft-autumn.png";
import JewelrySoftAutumn from "../assets/images/all-color-chart-icons/tone-soft-autumn/jewelry-soft-autumn.png";

import LipstickHotAutumn from "../assets/images/all-color-chart-icons/tone-hot-autumn/lipstick-hot-autumn.png";
import PencilHotAutumn from "../assets/images/all-color-chart-icons/tone-hot-autumn/pencil-hot-autumn.png";
import BlushHotAutumn from "../assets/images/all-color-chart-icons/tone-hot-autumn/blush-hot-autumn.png";
import ShadowHotAutumn from "../assets/images/all-color-chart-icons/tone-hot-autumn/shadow-hot-autumn.png";
import HairHotAutumn from "../assets/images/all-color-chart-icons/tone-hot-autumn/hair-hot-autumn.png";
import JewelryHotAutumn from "../assets/images/all-color-chart-icons/tone-hot-autumn/jewelry-hot-autumn.png";

import LipstickColdWinter from "../assets/images/all-color-chart-icons/tone-cold-winter/lipstick-cold-winter.png";
import PencilColdWinter from "../assets/images/all-color-chart-icons/tone-cold-winter/pencil-cold-winter.png";
import BlushColdWinter from "../assets/images/all-color-chart-icons/tone-cold-winter/blush-cold-winter.png";
import ShadowColdWinter from "../assets/images/all-color-chart-icons/tone-cold-winter/shadow-cold-winter.png";
import HairColdWinter from "../assets/images/all-color-chart-icons/tone-cold-winter/hair-cold-winter.png";
import JewelryColdWinter from "../assets/images/all-color-chart-icons/tone-cold-winter/jewelry-cold-winter.png";

import LipstickBrightWinter from "../assets/images/all-color-chart-icons/tone-bright-winter/lipstick-bright-winter.png";
import PencilBrightWinter from "../assets/images/all-color-chart-icons/tone-bright-winter/pencil-bright-winter.png";
import BlushBrightWinter from "../assets/images/all-color-chart-icons/tone-bright-winter/blush-bright-winter.png";
import ShadowBrightWinter from "../assets/images/all-color-chart-icons/tone-bright-winter/shadow-bright-winter.png";
import HairBrightWinter from "../assets/images/all-color-chart-icons/tone-bright-winter/hair-bright-winter.png";
import JewelryBrightWinter from "../assets/images/all-color-chart-icons/tone-bright-winter/jewelry-bright-winter.png";

import LipstickDarkWinter from "../assets/images/all-color-chart-icons/tone-dark-winter/lipstick-dark-winter.png";
import PencilDarkWinter from "../assets/images/all-color-chart-icons/tone-dark-winter/pencil-dark-winter.png";
import BlushDarkWinter from "../assets/images/all-color-chart-icons/tone-dark-winter/blush-dark-winter.png";
import ShadowDarkWinter from "../assets/images/all-color-chart-icons/tone-dark-winter/shadow-dark-winter.png";
import HairDarkWinter from "../assets/images/all-color-chart-icons/tone-dark-winter/hair-dark-winter.png";
import JewelryDarkWinter from "../assets/images/all-color-chart-icons/tone-dark-winter/jewelry-dark-winter.png";

import FlowerIconSVG from "../assets/images/all-color-chart-icons/flower-icon.svg";
import SunIconSVG from "../assets/images/all-color-chart-icons/sun-icon.svg";
import LeafIconSVG from "../assets/images/all-color-chart-icons/leaf-icon.svg";
import SnowflakeIconSVG from "../assets/images/all-color-chart-icons/snowflake-icon.svg";

export const allColorChart = {
  brightSpring: {
    nameIcon: {
      name: "Primavera Brilhante",
      src: FlowerIconSVG,
    },
    tone: {
      lipstick: LipstickBrightSpring,
      pencil: PencilBrightSpring,
      blush: BlushBrightSpring,
      shadow: ShadowBrightSpring,
      hair: HairBrightSpring,
      jewelry: JewelryBrightSpring,
    },
    colors: [
      "#fcfaf2",
      "#f6f1de",
      "#fcf2db",
      "#fcecce",
      "#f2e0c2",
      "#e9cb9d",
      "#e1bc82",
      "#d9af6a",
      "#c9bfb2",
      "#ab9c88",
      "#96846c",
      "#826e54",
      "#7e4f46",
      "#5f2923",
      "#51342d",
      "#35242b",
      "#fad998",
      "#f7c969",
      "#f5ba31",
      "#f6a334",
      "#fff4b6",
      "#ffec82",
      "#ffe74f",
      "#ffe200",
      "#fac8be",
      "#f3978a",
      "#f69a99",
      "#f27078",
      "#f5a07a",
      "#f18559",
      "#f37121",
      "#e06135",
      "#f58d70",
      "#f37052",
      "#f04e37",
      "#ed1c24",
      "#f49f95",
      "#ef736e",
      "#ec555a",
      "#eb3b4e",
      "#f69d8f",
      "#f1746a",
      "#ed4d50",
      "#c62f37",
      "#db86a6",
      "#d46c96",
      "#cd4483",
      "#c2335e",
      "#d9778c",
      "#ce4d70",
      "#a62451",
      "#7c3058",
      "#c4b0c9",
      "#ad90b1",
      "#8d679d",
      "#855593",
      "#baa2bf",
      "#8c658e",
      "#784b79",
      "#4b2441",
      "#85bbd5",
      "#3096b8",
      "#007da3",
      "#00688f",
      "#77cfe4",
      "#01bbd7",
      "#00a6c5",
      "#0098bb",
      "#9bd2dc",
      "#4db9c7",
      "#00a7b8",
      "#0099aa",
      "#cde08e",
      "#b6d34f",
      "#afb94a",
      "#9daa1d",
      "#acd692",
      "#82c55b",
      "#8fac64",
      "#7da24e",
      "#9acca1",
      "#7dc18a",
      "#5bb672",
      "#17a956",
      "#72a17b",
      "#528d61",
      "#2b7c49",
      "#076c34",
    ],
    complements: [
      "#00688f",
      "#e06135",
      "#0098bb",
      "#f37121",
      "#f5ba31",
      "#4b4a83",
      "#f6a334",
      "#8d679d",
      "#f27078",
      "#b6d34f",
      "#c62f37",
      "#7da24e",
    ],
    analogous: [
      "#f27078",
      "#f18559",
      "#ed4d50",
      "#f37121",
      "#cd4483",
      "#c2335e",
      "#8d679d",
      "#0098bb",
      "#02a99f",
      "#01bbd7",
      "#f5ba31",
      "#e06135",
    ],
    triad: [
      "#00688f",
      "#f5ba31",
      "#ed4d50",
      "#01bbd7",
      "#ffe200",
      "#c62f37",
      "#255c9e",
      "#e3b15b",
      "#ed4d50",
      "#f18559",
      "#ad90b1",
      "#82c55b",
      "#e06135",
      "#8d679d",
      "#17a956",
      "#f37121",
      "#4b4a83",
      "#076c34",
    ],
  },

  clearSpring: {
    nameIcon: {
      name: "Primavera Clara",
      src: FlowerIconSVG,
    },
    tone: {
      lipstick: LipstickClearSpring,
      pencil: PencilClearSpring,
      blush: BlushClearSpring,
      shadow: ShadowClearSpring,
      hair: HairClearSpring,
      jewelry: JewelryClearSpring,
    },
    colors: [
      "#fcf2db",
      "#fcecce",
      "#dec3b7",
      "#d8b6a7",
      "#e4cbb9",
      "#dab8a1",
      "#d7aa92",
      "#ce997e",
      "#e1ba96",
      "#cc935f",
      "#bd7836",
      "#ac600e",
      "#be8f72",
      "#a46948",
      "#945928",
      "#7c3d05",
      "#d2d2d1",
      "#c2c3c2",
      "#b0a8a5",
      "#9f9694",
      "#ece8b4",
      "#e5de96",
      "#ffed8a",
      "#ffe96a",
      "#ffe6b0",
      "#ffd67c",
      "#fec94e",
      "#febe10",
      "#fcd4c0",
      "#fbc4aa",
      "#f6aa9e",
      "#f3978a",
      "#efb4ae",
      "#e89591",
      "#e37e7d",
      "#d87682",
      "#fcd0be",
      "#f9b299",
      "#f79a7e",
      "#f48466",
      "#f58d70",
      "#f37052",
      "#f04e37",
      "#ed1c24",
      "#f2857c",
      "#ee6061",
      "#eb3b4e",
      "#e04242",
      "#e37994",
      "#de567f",
      "#e05b70",
      "#dd4665",
      "#ddc8d7",
      "#cab7d3",
      "#ad93c5",
      "#9e7eb9",
      "#9a87bf",
      "#826aaf",
      "#7a579b",
      "#6b408e",
      "#bce0e2",
      "#91cdd1",
      "#80d0d9",
      "#66cad3",
      "#5bc9e0",
      "#01bbd7",
      "#009fc8",
      "#0094c2",
      "#85bbd5",
      "#3096b8",
      "#007da3",
      "#00688f",
      "#bedaaa",
      "#add298",
      "#a0d182",
      "#90ca6c",
      "#a5d5a0",
      "#88ca84",
      "#6abf5d",
      "#44b749",
      "#e6eabd",
      "#d7dd91",
      "#cbd370",
      "#bfc94a",
      "#b8c187",
      "#a2b164",
      "#789650",
      "#658737",
    ],
    complements: [
      "#3f517d",
      "#f48466",
      "#66cad3",
      "#f3978a",
      "#9e7eb9",
      "#e5de96",
      "#6b408e",
      "#ffe96a",
      "#add298",
      "#d87682",
      "#44b749",
      "#de567f",
    ],
    analogous: [
      "#0094c2",
      "#88ca84",
      "#9e7eb9",
      "#91cdd1",
      "#de567f",
      "#eb3b4e",
      "#e37e7d",
      "#f48466",
      "#e5de96",
      "#fbc4aa",
      "#e5de96",
      "#ffe96a",
    ],
    triad: [
      "#91cdd1",
      "#fcecce",
      "#e37e7d",
      "#66cad3",
      "#ffe96a",
      "#e04242",
      "#0094c2",
      "#e5de96",
      "#eb3b4e",
      "#ddc8d7",
      "#90ca6c",
      "#f48466",
      "#826aaf",
      "#88ca84",
      "#fbc4aa",
      "#6b408e",
      "#44b749",
      "#f3978a",
    ],
  },

  hotSpring: {
    nameIcon: {
      name: "Primavera Quente",
      src: FlowerIconSVG,
    },
    tone: {
      lipstick: LipstickHotSpring,
      pencil: PencilHotSpring,
      blush: BlushHotSpring,
      shadow: ShadowHotSpring,
      hair: HairHotSpring,
      jewelry: JewelryHotSpring,
    },
    colors: [
      "#faf5e1",
      "#f6ebc4",
      "#ffe3b6",
      "#ffdeaa",
      "#f2e0c2",
      "#e9cb9d",
      "#e1bc82",
      "#d9af6a",
      "#e1ba96",
      "#cc935f",
      "#bd7836",
      "#ac600e",
      "#cda189",
      "#ae7153",
      "#af7e62",
      "#a46d4e",
      "#bda998",
      "#997b66",
      "#815f44",
      "#6a452b",
      "#fff4b6",
      "#ffec82",
      "#ffe74f",
      "#ffe200",
      "#f9d385",
      "#f7c65e",
      "#f5ba31",
      "#cea253",
      "#fdd1a7",
      "#fbb473",
      "#f89d49",
      "#f6881f",
      "#fbc3a5",
      "#f68d60",
      "#f15c2b",
      "#e24d34",
      "#f58d70",
      "#f37052",
      "#f04e37",
      "#ed1c24",
      "#f7a28c",
      "#f58b74",
      "#f3745f",
      "#f15a4a",
      "#eba19c",
      "#e37e7d",
      "#f26c6f",
      "#e45c69",
      "#f38e84",
      "#ef6a67",
      "#eb3b4e",
      "#910710",
      "#b18fb4",
      "#946395",
      "#8d679d",
      "#855593",
      "#8c658e",
      "#784b79",
      "#644457",
      "#4b2441",
      "#bbe4eb",
      "#89d3de",
      "#5bc8d5",
      "#10bccd",
      "#63c9d7",
      "#22bfcf",
      "#00a7c6",
      "#0099bc",
      "#85bbd5",
      "#3096b8",
      "#007da3",
      "#00688f",
      "#b0d896",
      "#90ca6c",
      "#93be67",
      "#81b650",
      "#89c978",
      "#4cb848",
      "#4cb26b",
      "#17a956",
      "#c4db75",
      "#b6d34f",
      "#afb94a",
      "#9daa1d",
      "#a1b87c",
      "#7da24e",
      "#697d2d",
      "#556c11",
    ],
    complements: [
      "#556c11",
      "#f68d60",
      "#9481b7",
      "#e24d34",
      "#e45c69",
      "#90ca6c",
      "#f26c6f",
      "#b6d34f",
      "#806aa8",
      "#f5ba31",
      "#946395",
      "#f6881f",
    ],
    analogous: [
      "#e45c69",
      "#f68d60",
      "#f15a4a",
      "#eb3b4e",
      "#007da4",
      "#806aa8",
      "#22bfcf",
      "#90ca6c",
      "#e24d34",
      "#ffe200",
      "#ffdeaa",
      "#f68d60",
    ],
    triad: [
      "#f15a4a",
      "#ffe200",
      "#0099bc",
      "#eb3b4e",
      "#cea253",
      "#147b9e",
      "#ffdeaa",
      "#22bfcf",
      "#ed4d50",
      "#946395",
      "#b6d34f",
      "#f68d60",
      "#56397e",
      "#7da24e",
      "#ed1c24",
      "#806aa8",
      "#4cb848",
      "#e24d34",
    ],
  },

  coldSummer: {
    nameIcon: {
      name: "Verão Frio",
      src: SunIconSVG,
    },
    tone: {
      lipstick: LipstickColdSummer,
      pencil: PencilColdSummer,
      blush: BlushColdSummer,
      shadow: ShadowColdSummer,
      hair: HairColdSummer,
      jewelry: JewelryColdSummer,
    },
    colors: [
      "#f4eee7",
      "#f0e9e0",
      "#dbd3cf",
      "#d5cbc7",
      "#ded5d6",
      "#ccbfc1",
      "#bcaaad",
      "#a58d92",
      "#d2d2d1",
      "#c2c3c2",
      "#8e8f92",
      "#757679",
      "#cdc8c7",
      "#a59d9b",
      "#77706e",
      "#5a5553",
      "#aec2cf",
      "#84a3b3",
      "#6d93b5",
      "#5080a5",
      "#7e86aa",
      "#2b5486",
      "#1b4a73",
      "#083e67",
      "#f6fae4",
      "#f7f7be",
      "#f4f194",
      "#e2d777",
      "#fad3e4",
      "#f7b7d3",
      "#e29cb6",
      "#db85a6",
      "#f6accc",
      "#f176ae",
      "#d0719b",
      "#c84b88",
      "#d59fbb",
      "#c677a0",
      "#b84a88",
      "#a43263",
      "#e6899e",
      "#de567f",
      "#dd4665",
      "#c2335e",
      "#b38693",
      "#93516b",
      "#802b55",
      "#852848",
      "#c8bfde",
      "#aa9bcb",
      "#8382b0",
      "#6d6da1",
      "#c5a1c0",
      "#aa729e",
      "#a882a2",
      "#9d7195",
      "#ac8eb7",
      "#855593",
      "#6a508a",
      "#56397e",
      "#979ccb",
      "#6070b1",
      "#61698f",
      "#3f517d",
      "#9fc7e0",
      "#60abd0",
      "#4ec6de",
      "#01bbd7",
      "#bcd4eb",
      "#94bbdf",
      "#6da8d5",
      "#1e8dc5",
      "#a9cbcd",
      "#6cacaf",
      "#109596",
      "#1d7476",
      "#bad3cc",
      "#81b0a5",
      "#559688",
      "#398878",
      "#b9e1d7",
      "#8ad0bf",
      "#5ebaad",
      "#16ac9c",
      "#7dcbb8",
      "#27bba1",
      "#00a881",
      "#00896a",
    ],
    complements: [
      "#2b5486",
      "#f7986e",
      "#60abd0",
      "#fbb384",
      "#8ad0bf",
      "#db85a6",
      "#16ac9c",
      "#de567f",
      "#855593",
      "#e2d777",
      "#56397e",
      "#f4f194",
    ],
    analogous: [
      "#01bbd7",
      "#8ad0bf",
      "#56397e",
      "#60abd0",
      "#db85a6",
      "#a43263",
      "#dd4665",
      "#c84b88",
      "#6d6da1",
      "#e5a6be",
      "#f7986e",
      "#e2d777",
    ],
    triad: [
      "#5080a5",
      "#e2d777",
      "#852848",
      "#60abd0",
      "#f4f194",
      "#dd4665",
      "#01bbd7",
      "#f6fae4",
      "#db85a6",
      "#00a881",
      "#f7986e",
      "#855593",
      "#8ad0bf",
      "#fbb384",
      "#9d7195",
      "#1d7476",
      "#fdd6bc",
      "#56397e",
    ],
  },

  clearSummer: {
    nameIcon: {
      name: "Verão Claro",
      src: SunIconSVG,
    },
    tone: {
      lipstick: LipstickClearSummer,
      pencil: PencilClearSummer,
      blush: BlushClearSummer,
      shadow: ShadowClearSummer,
      hair: HairClearSummer,
      jewelry: JewelryClearSummer,
    },
    colors: [
      "#fbf9f6",
      "#f6f2ed",
      "#f0e9e0",
      "#e3d9d1",
      "#bcaaad",
      "#a58d92",
      "#b2a4a3",
      "#a29292",
      "#d5c3b8",
      "#cdb6a9",
      "#e2c2bf",
      "#d7adaa",
      "#d4d4d5",
      "#bebec0",
      "#a6a8a7",
      "#94a19f",
      "#aba6aa",
      "#8b868b",
      "#6f6b72",
      "#57545c",
      "#f8f8e7",
      "#f0efc8",
      "#eae5aa",
      "#e5de96",
      "#f5d6db",
      "#ecb0bb",
      "#e4a0bc",
      "#dd8bac",
      "#f9c2c1",
      "#f48b92",
      "#e46d7b",
      "#dd4665",
      "#f59fc4",
      "#f176ae",
      "#e1708e",
      "#de567f",
      "#eaa5b3",
      "#dc6d8c",
      "#c65472",
      "#be3461",
      "#ddacc7",
      "#cc7ea9",
      "#c15592",
      "#96377c",
      "#cacce2",
      "#a4a9cd",
      "#8b92be",
      "#6e78ac",
      "#beb2d6",
      "#9d88bf",
      "#77629c",
      "#684f90",
      "#a6aad3",
      "#7b85be",
      "#6070b1",
      "#4d557d",
      "#7c9ebd",
      "#5080a5",
      "#61698f",
      "#3f517d",
      "#82d2e5",
      "#4ec6dd",
      "#a8c4df",
      "#87afd2",
      "#a7d1ec",
      "#7dbfe3",
      "#4dadda",
      "#009dd1",
      "#98c2d7",
      "#6baac5",
      "#3b93b3",
      "#0d80a2",
      "#94a5b4",
      "#577d91",
      "#568c9d",
      "#177a8c",
      "#a9cbcd",
      "#80b5b8",
      "#55a4a7",
      "#109596",
      "#b4dfd4",
      "#8ad0bf",
      "#63b9ad",
      "#3eafa0",
      "#97d5c5",
      "#47bfa7",
      "#00a881",
      "#00896a",
    ],
    complements: [
      "#5080a5",
      "#f48b92",
      "#009dd1",
      "#dd4665",
      "#e5de96",
      "#684f90",
      "#f0efc8",
      "#9d88bf",
      "#3eafa0",
      "#ecb0bb",
      "#177a8c",
      "#c15592",
    ],
    analogous: [
      "#009dd1",
      "#3eafa0",
      "#6070b1",
      "#9d88bf",
      "#f48b92",
      "#f0efc8",
      "#73a5c4",
      "#684f90",
      "#8ad0bf",
      "#87afd2",
      "#f48b92",
      "#dd4665",
    ],
    triad: [
      "#109596",
      "#684f90",
      "#facecc",
      "#00a881",
      "#9d88bf",
      "#dd4665",
      "#00896a",
      "#4d557d",
      "#f48b92",
      "#add7df",
      "#f7f5df",
      "#dd8bac",
      "#73a5c4",
      "#f0efc8",
      "#de567f",
      "#5080a5",
      "#e5de96",
      "#c15592",
    ],
  },

  softSummer: {
    nameIcon: {
      name: "Verão Suave",
      src: SunIconSVG,
    },
    tone: {
      lipstick: LipstickSoftSummer,
      pencil: PencilSoftSummer,
      blush: BlushSoftSummer,
      shadow: ShadowSoftSummer,
      hair: HairSoftSummer,
      jewelry: JewelrySoftSummer,
    },
    colors: [
      "#f8f5f2",
      "#f0e9e0",
      "#d9c9bf",
      "#cdb6a9",
      "#c3b7b7",
      "#a29292",
      "#b09a9e",
      "#a58d92",
      "#cdbfb8",
      "#b6a399",
      "#a0887e",
      "#886e62",
      "#d4d4d5",
      "#bebec0",
      "#a6a8a7",
      "#94a19f",
      "#a5a2ac",
      "#7d7a88",
      "#5c5c6d",
      "#333d52",
      "#f8f8e7",
      "#f0efc8",
      "#eae5aa",
      "#e5de96",
      "#f2d6cf",
      "#e9c2b8",
      "#e2ada4",
      "#d8968c",
      "#f4c3c1",
      "#eea4a6",
      "#e7868d",
      "#e06171",
      "#f2cad1",
      "#ecb0bb",
      "#cc93a8",
      "#c27c98",
      "#f7b2d0",
      "#f391bc",
      "#f176ae",
      "#e183b6",
      "#e18da4",
      "#db7393",
      "#d1728a",
      "#cc5a7b",
      "#d0adb6",
      "#bb8896",
      "#a8657c",
      "#933962",
      "#b38693",
      "#93516b",
      "#802b55",
      "#852848",
      "#a2add3",
      "#8796c6",
      "#687db6",
      "#7073a5",
      "#ac95ad",
      "#886a8c",
      "#7f6c8a",
      "#6e597c",
      "#a085a4",
      "#8b648d",
      "#724373",
      "#612960",
      "#bddbdf",
      "#92c7cd",
      "#52a9b4",
      "#2b9da9",
      "#94a5b4",
      "#577d91",
      "#4989a9",
      "#147b9e",
      "#7fa2b0",
      "#177a8c",
      "#4f7887",
      "#2a6a79",
      "#b3dcd8",
      "#82c8c2",
      "#5ebaad",
      "#16ac9c",
      "#9dc3c6",
      "#72abaf",
      "#48969a",
      "#138287",
      "#a7b4a8",
      "#77917e",
      "#5c7c79",
      "#306764",
    ],
    complements: [
      "#e5de96",
      "#7073a5",
      "#e0e1a7",
      "#6e597c",
      "#82c8c2",
      "#ecb0bb",
      "#138287",
      "#e183b6",
      "#2b9da9",
      "#f7986e",
      "#147b9e",
      "#fbb384",
    ],
    analogous: [
      "#2b9da9",
      "#836488",
      "#2a6a79",
      "#77917e",
      "#db7393",
      "#f7986e",
      "#852848",
      "#e183b6",
      "#e0e1a7",
      "#fbb384",
      "#612960",
      "#933962",
    ],
    triad: [
      "#687db6",
      "#e5de96",
      "#db7393",
      "#333d52",
      "#e0e1a7",
      "#852848",
      "#2b9da9",
      "#f1eec8",
      "#e183b6",
      "#82c8c2",
      "#f7986e",
      "#836488",
      "#16ac9c",
      "#fbb384",
      "#6f4d65",
      "#138287",
      "#fcc9ae",
      "#612960",
    ],
  },

  darkAutumn: {
    nameIcon: {
      name: "Outono Escuro",
      src: LeafIconSVG,
    },
    tone: {
      lipstick: LipstickDarkAutumn,
      pencil: PencilDarkAutumn,
      blush: BlushDarkAutumn,
      shadow: ShadowDarkAutumn,
      hair: HairDarkAutumn,
      jewelry: JewelryDarkAutumn,
    },
    colors: [
      "#fcf2db",
      "#fcecce",
      "#e7c5a7",
      "#ddaf87",
      "#ddbc9b",
      "#d0a47b",
      "#d1a975",
      "#c79958",
      "#bb9076",
      "#a46d4e",
      "#916b58",
      "#7e533e",
      "#9b8478",
      "#897064",
      "#74584b",
      "#6a4534",
      "#a58a82",
      "#8c6c63",
      "#704b43",
      "#4d2c29",
      "#bb7b69",
      "#ad6150",
      "#a26349",
      "#914630",
      "#ad746f",
      "#93474b",
      "#74212c",
      "#54000a",
      "#a86b74",
      "#984d5f",
      "#85575e",
      "#703847",
      "#c7ad84",
      "#b2915b",
      "#a37d3b",
      "#7e5000",
      "#f1cd92",
      "#eabb6d",
      "#e8b35a",
      "#e0a230",
      "#f6bd86",
      "#f1a661",
      "#ec8e31",
      "#d47938",
      "#f49b74",
      "#f18559",
      "#e46356",
      "#e04242",
      "#d28873",
      "#c76753",
      "#bc4339",
      "#b43744",
      "#e99a9f",
      "#e27c87",
      "#db5d71",
      "#c5304f",
      "#c3787f",
      "#b05562",
      "#9f2e48",
      "#8b0029",
      "#977499",
      "#784b79",
      "#582450",
      "#4b2441",
      "#7fa2b1",
      "#4b8294",
      "#11677a",
      "#005063",
      "#5a92b2",
      "#1d80a4",
      "#007090",
      "#006081",
      "#bac395",
      "#a7b378",
      "#93a55c",
      "#6fa553",
      "#c4c271",
      "#b0af45",
      "#9a9445",
      "#837c1b",
      "#7ba98d",
      "#32825a",
      "#47144",
      "#5825",
      "#878b75",
      "#687257",
      "#4b5e3e",
      "#435249",
    ],
    complements: [
      "#8b0029",
      "#4b5e3e",
      "#db5d71",
      "#5063",
      "#6081",
      "#f18559",
      "#4b2441",
      "#e04242",
      "#703847",
      "#c79958",
      "#784b79",
      "#7e5000",
    ],
    analogous: [
      "#784b79",
      "#6081",
      "#db5d71",
      "#4b2441",
      "#7e5000",
      "#ec8e31",
      "#f18559",
      "#c5304f",
      "#e27c87",
      "#8b0029",
      "#1d80a4",
      "#005063",
    ],
    triad: [
      "#344a4c",
      "#c79958",
      "#8b0029",
      "#006081",
      "#7e5000",
      "#b43744",
      "#1d80a4",
      "#8b0029",
      "#ec8e31",
      "#784b79",
      "#f18559",
      "#005063",
      "#703847",
      "#bc4339",
      "#b0af45",
      "#4b2441",
      "#e04242",
      "#4b5e3e",
    ],
  },

  softAutumn: {
    nameIcon: {
      name: "Outono Suave",
      src: LeafIconSVG,
    },
    tone: {
      lipstick: LipstickSoftAutumn,
      pencil: PencilSoftAutumn,
      blush: BlushSoftAutumn,
      shadow: ShadowSoftAutumn,
      hair: HairSoftAutumn,
      jewelry: JewelrySoftAutumn,
    },
    colors: [
      "#fef2e5",
      "#fee9d5",
      "#fcf3df",
      "#fcecce",
      "#e8d2c2",
      "#dab8a1",
      "#e3c2b6",
      "#d3a292",
      "#cda186",
      "#ae704f",
      "#9b6552",
      "#874c37",
      "#ac7f77",
      "#966159",
      "#824640",
      "#733622",
      "#ab7170",
      "#934f51",
      "#74212c",
      "#54000a",
      "#9994a5",
      "#756f84",
      "#52516b",
      "#313757",
      "#afa79b",
      "#908776",
      "#746a56",
      "#5b513c",
      "#fbdea8",
      "#f9d083",
      "#f7c35b",
      "#f4b72c",
      "#eecd97",
      "#e9bf7a",
      "#e3b15b",
      "#c79958",
      "#f5a682",
      "#f18559",
      "#f47c5e",
      "#f15a40",
      "#dfab99",
      "#d28873",
      "#c45f4c",
      "#bc4339",
      "#cd8379",
      "#c0605c",
      "#b43744",
      "#952b37",
      "#f9c3b8",
      "#f7b4a8",
      "#f3978a",
      "#e46c67",
      "#f7aba6",
      "#f59391",
      "#f27578",
      "#f05a67",
      "#cc9296",
      "#ba6b75",
      "#ad4a5f",
      "#984d5f",
      "#a892c0",
      "#8d6faa",
      "#75618e",
      "#5f4779",
      "#a28c96",
      "#806573",
      "#644457",
      "#4b2441",
      "#91c1cc",
      "#6db0bc",
      "#379dab",
      "#147b9e",
      "#7f95a2",
      "#597e8c",
      "#2a6a79",
      "#306764",
      "#a0d39a",
      "#70a86f",
      "#71ba8b",
      "#44ad73",
      "#b4c796",
      "#9ab372",
      "#7f9b58",
      "#658737",
      "#8ba98b",
      "#689570",
      "#3b8455",
      "#4b5e3e",
    ],
    complements: [
      "#9ab372",
      "#c96673",
      "#4b5e3e",
      "#952b37",
      "#2a6a79",
      "#f15a40",
      "#313757",
      "#bc4339",
      "#4b2441",
      "#e3b15b",
      "#8d6faa",
      "#fcecce",
    ],
    analogous: [
      "#8d6faa",
      "#5f4779",
      "#379dab",
      "#88ca84",
      "#44ad73",
      "#4b2441",
      "#874c37",
      "#e46c67",
      "#e3b15b",
      "#f15a40",
      "#f3978a",
      "#952b37",
    ],
    triad: [
      "#313757",
      "#f18559",
      "#b43744",
      "#379dab",
      "#e3b15b",
      "#f05a67",
      "#f15a40",
      "#8d6faa",
      "#9ab372",
      "#bc4339",
      "#e46c67",
      "#306764",
      "#f3978a",
      "#f15a40",
      "#44ad73",
      "#e46c67",
      "#658737",
      "#f18559",
    ],
  },

  hotAutumn: {
    nameIcon: {
      name: "Outono Quente",
      src: LeafIconSVG,
    },
    tone: {
      lipstick: LipstickHotAutumn,
      pencil: PencilHotAutumn,
      blush: BlushHotAutumn,
      shadow: ShadowHotAutumn,
      hair: HairHotAutumn,
      jewelry: JewelryHotAutumn,
    },
    colors: [
      "#fcf2db",
      "#fcecce",
      "#e7c5a7",
      "#ddaf87",
      "#e4ae8d",
      "#d99169",
      "#c28976",
      "#ad6150",
      "#bb9076",
      "#a46d4e",
      "#88533c",
      "#733622",
      "#a68c7f",
      "#917464",
      "#805f4e",
      "#6a4534",
      "#f4dcb5",
      "#ebc688",
      "#e3b15b",
      "#c49757",
      "#fce8bf",
      "#fad998",
      "#f7c65e",
      "#f5ba31",
      "#fdca92",
      "#fbb66b",
      "#db9d61",
      "#d48940",
      "#e9b68e",
      "#db9058",
      "#c76845",
      "#bf5030",
      "#f5a682",
      "#f18559",
      "#f68a47",
      "#f36f21",
      "#f8a48d",
      "#f58870",
      "#f26c57",
      "#cf3b36",
      "#f7b1a6",
      "#f38e84",
      "#ef6a67",
      "#eb3b4e",
      "#c86756",
      "#c05042",
      "#be4c4e",
      "#b7293b",
      "#de8681",
      "#d25a5d",
      "#a03844",
      "#8b0029",
      "#ab7170",
      "#934f51",
      "#74212c",
      "#54000a",
      "#cab7cc",
      "#af91b0",
      "#8c618a",
      "#774574",
      "#ae8aa9",
      "#996a90",
      "#7f4674",
      "#4b2441",
      "#9ac6cf",
      "#76b4bf",
      "#379dab",
      "#147b9e",
      "#4f909a",
      "#0c7881",
      "#00646c",
      "#005063",
      "#779cac",
      "#508294",
      "#166779",
      "#003453",
      "#bcc68e",
      "#a2b465",
      "#7fb072",
      "#64a254",
      "#cec365",
      "#bcb130",
      "#9a9445",
      "#837c1b",
      "#979f59",
      "#818a37",
      "#686f3c",
      "#464e15",
    ],
    complements: [
      "#147b9e",
      "#d47938",
      "#5063",
      "#f18559",
      "#b7293b",
      "#4b5e3e",
      "#64a254",
      "#eb3b4e",
      "#774574",
      "#c79958",
      "#f5ba31",
      "#4b2441",
    ],
    analogous: [
      "#4b2441",
      "#005063",
      "#774574",
      "#379dab",
      "#147b9e",
      "#64a254",
      "#b7293b",
      "#d47938",
      "#f5ba31",
      "#f36f21",
      "#eb3b4e",
      "#8b0029",
    ],
    triad: [
      "#b7293b",
      "#003453",
      "#e3b15b",
      "#cf3b36",
      "#147b9e",
      "#f5ba31",
      "#f18559",
      "#774574",
      "#a2b465",
      "#f36f21",
      "#4b2441",
      "#64a254",
      "#d25a5d",
      "#e3b15b",
      "#00646c",
      "#eb3b4e",
      "#f5ba31",
      "#005063",
    ],
  },

  coldWinter: {
    nameIcon: {
      name: "Inverno Frio",
      src: SnowflakeIconSVG,
    },
    tone: {
      lipstick: LipstickColdWinter,
      pencil: PencilColdWinter,
      blush: BlushColdWinter,
      shadow: ShadowColdWinter,
      hair: HairColdWinter,
      jewelry: JewelryColdWinter,
    },
    colors: [
      "#ffffff",
      "#ebebeb",
      "#d2d2d1",
      "#c2c3c2",
      "#cce0ef",
      "#d1ebe5",
      "#fbdde6",
      "#f7f8ce",
      "#86878c",
      "#666c73",
      "#696567",
      "#4e4f51",
      "#746c6f",
      "#544d50",
      "#312b2e",
      "#0c090d",
      "#f4f4b6",
      "#eeee86",
      "#e7dd74",
      "#e4d64c",
      "#f6adcd",
      "#f287b7",
      "#ee4c9b",
      "#ec008c",
      "#d4809a",
      "#ca5d84",
      "#c33b74",
      "#a43263",
      "#c67b99",
      "#be6088",
      "#b13574",
      "#750349",
      "#f7adc5",
      "#d8428d",
      "#d70074",
      "#ed1558",
      "#997699",
      "#8c648d",
      "#7c4c7c",
      "#6b326e",
      "#998db3",
      "#6b5a8c",
      "#775b8b",
      "#64467d",
      "#999ec6",
      "#757eb0",
      "#5c69a1",
      "#4c4681",
      "#90789f",
      "#7860a2",
      "#5a388c",
      "#4a2a74",
      "#b5d2e7",
      "#89b8d7",
      "#698fbd",
      "#5282b4",
      "#9ca2d1",
      "#6b79bb",
      "#4d65af",
      "#1d4ca1",
      "#8595bc",
      "#5871a2",
      "#2a548a",
      "#003c77",
      "#8384a3",
      "#61698f",
      "#3f517d",
      "#313757",
      "#a5dad0",
      "#76b6af",
      "#42a69b",
      "#0e9b8e",
      "#95d5ca",
      "#6fc8bb",
      "#17bba8",
      "#00ae97",
      "#50ac9f",
      "#29988",
      "#008973",
      "#00896a",
      "#7f9fa3",
      "#55898c",
      "#1c7779",
      "#06595a",
      "#4c7a83",
      "#155e67",
      "#00474f",
      "#003d2c",
    ],
    complements: [
      "#89b8d7",
      "#e98c63",
      "#1d4ca1",
      "#de5d2d",
      "#00ae97",
      "#f7adc5",
      "#00474f",
      "#b13574",
      "#5a388c",
      "#eeee86",
      "#6b326e",
      "#e4d64c",
    ],
    analogous: [
      "#8ed2c5",
      "#89b8d7",
      "#ed1558",
      "#ec008c",
      "#6b326e",
      "#1d4ca1",
      "#5a388c",
      "#973f70",
      "#00474f",
      "#1d4ca1",
      "#d70074",
      "#5a388c",
    ],
    triad: [
      "#00ae97",
      "#6b5a8c",
      "#de5d2d",
      "#003d2c",
      "#5a388c",
      "#e98c63",
      "#00474f",
      "#6b326e",
      "#f6c8af",
      "#5282b4",
      "#750349",
      "#e4d64c",
      "#3f517d",
      "#ec008c",
      "#eeee86",
      "#313757",
      "#f7adc5",
      "#f5f5bc",
    ],
  },

  brightWinter: {
    nameIcon: {
      name: "Inverno Brilhante",
      src: SnowflakeIconSVG,
    },
    tone: {
      lipstick: LipstickBrightWinter,
      pencil: PencilBrightWinter,
      blush: BlushBrightWinter,
      shadow: ShadowBrightWinter,
      hair: HairBrightWinter,
      jewelry: JewelryBrightWinter,
    },
    colors: [
      "#ffffff",
      "#ebebeb",
      "#d2d2d1",
      "#c2c3c2",
      "#cce0ef",
      "#d1ebe5",
      "#fbdde6",
      "#f7f8ce",
      "#a3abae",
      "#808d90",
      "#617376",
      "#435d60",
      "#b0aaab",
      "#8f898b",
      "#736f71",
      "#59595c",
      "#80797b",
      "#544d50",
      "#312b2e",
      "#0c090d",
      "#80687e",
      "#60445f",
      "#401a3f",
      "#35242b",
      "#eae29d",
      "#e2d777",
      "#e8df4e",
      "#e1d708",
      "#f9c9de",
      "#f287b7",
      "#ee4c9b",
      "#ec008c",
      "#dd99b6",
      "#d279a0",
      "#c84b88",
      "#b13574",
      "#bc729c",
      "#a63c7a",
      "#8c3264",
      "#750349",
      "#f2777a",
      "#f05865",
      "#ee2b53",
      "#c00848",
      "#bca3c3",
      "#9f7aa0",
      "#895a8a",
      "#753877",
      "#c29dc4",
      "#ad7aae",
      "#9b589a",
      "#8f3e8d",
      "#9f8bc2",
      "#866db0",
      "#6a489d",
      "#64467d",
      "#7772ad",
      "#4f4b95",
      "#393688",
      "#353c60",
      "#809ec7",
      "#5282b4",
      "#2077b4",
      "#0062a6",
      "#8593c1",
      "#5671ad",
      "#255c9e",
      "#004892",
      "#6b87ac",
      "#2e5c87",
      "#054774",
      "#003462",
      "#96d4c9",
      "#6fc8ba",
      "#09aa8f",
      "#009775",
      "#6cc5a0",
      "#1cb687",
      "#06a177",
      "#009664",
      "#78aba4",
      "#3d8b80",
      "#007063",
      "#005949",
      "#27907f",
      "#007b63",
      "#0d4844",
      "#003730",
    ],
    complements: [
      "#004892",
      "#eb3b4e",
      "#003462",
      "#f38e84",
      "#e1d708",
      "#6a489d",
      "#e2d777",
      "#8f3e8d",
      "#003730",
      "#a63c7a",
      "#1cb687",
      "#f7b3d1",
    ],
    analogous: [
      "#009664",
      "#5282b4",
      "#004892",
      "#753877",
      "#ec008c",
      "#ee2b53",
      "#6a489d",
      "#c84b88",
      "#6fc8ba",
      "#003462",
      "#e1d708",
      "#eb3b4e",
    ],
    triad: [
      "#009664",
      "#6a489d",
      "#eb3b4e",
      "#005949",
      "#8f3e8d",
      "#ee2b53",
      "#003730",
      "#bca3c3",
      "#f38e84",
      "#255c9e",
      "#e1d708",
      "#c00848",
      "#0062a6",
      "#e2d777",
      "#c84b88",
      "#003462",
      "#efebb8",
      "#f7b3d1",
    ],
  },

  darkWinter: {
    nameIcon: {
      name: "Inverno Escuro",
      src: SnowflakeIconSVG,
    },
    tone: {
      lipstick: LipstickDarkWinter,
      pencil: PencilDarkWinter,
      blush: BlushDarkWinter,
      shadow: ShadowDarkWinter,
      hair: HairDarkWinter,
      jewelry: JewelryDarkWinter,
    },
    colors: [
      "#ffffff",
      "#ebebeb",
      "#d2d2d1",
      "#c2c3c2",
      "#fcf2de",
      "#fcecce",
      "#e4cbb9",
      "#dab8a1",
      "#b0aaab",
      "#8f898b",
      "#736f71",
      "#59595c",
      "#80797b",
      "#544d50",
      "#312b2e",
      "#0c090d",
      "#f9f7b6",
      "#f7f285",
      "#e7dd74",
      "#e4d64c",
      "#9994a5",
      "#756f84",
      "#52516b",
      "#313757",
      "#96898a",
      "#706063",
      "#504144",
      "#35242b",
      "#eda8c6",
      "#e580ae",
      "#de5396",
      "#d70080",
      "#f59bb9",
      "#f285ac",
      "#f06499",
      "#de567f",
      "#d58cb8",
      "#cc69a5",
      "#c33f91",
      "#a2156d",
      "#dd797c",
      "#d34e5d",
      "#ca0d45",
      "#a71643",
      "#b26e7e",
      "#a55a6d",
      "#943b56",
      "#7e0d3c",
      "#b0a1cd",
      "#9d88bf",
      "#936aa0",
      "#855593",
      "#785f94",
      "#56397e",
      "#623982",
      "#511c74",
      "#a469a4",
      "#8f3e8d",
      "#6a2968",
      "#590055",
      "#9a6b91",
      "#824876",
      "#6d235d",
      "#682b4c",
      "#a4c8e1",
      "#86a6d1",
      "#6491c4",
      "#3d7eb8",
      "#7694cd",
      "#487abd",
      "#0060af",
      "#004889",
      "#7990b3",
      "#496b94",
      "#144c79",
      "#003260",
      "#81c3b6",
      "#55b3a2",
      "#069f86",
      "#008b6b",
      "#78aba4",
      "#3d8b80",
      "#007063",
      "#005949",
      "#68817b",
      "#36675f",
      "#3c4c45",
      "#25322b",
    ],
    complements: [
      "#3d7eb8",
      "#ee2b53",
      "#89b8d7",
      "#f48a88",
      "#5cc4b7",
      "#de567f",
      "#00876d",
      "#ca0d45",
      "#855593",
      "#f7f285",
      "#511c74",
      "#e4d64c",
    ],
    analogous: [
      "#9d88bf",
      "#3d7eb8",
      "#d70080",
      "#ca0d45",
      "#e4d64c",
      "#f48a88",
      "#511c74",
      "#852848",
      "#7e0d3c",
      "#de567f",
      "#00876d",
      "#0060af",
    ],
    triad: [
      "#006a60",
      "#511c74",
      "#ee2b53",
      "#00876d",
      "#56397e",
      "#f48a88",
      "#3c4c45",
      "#9d88bf",
      "#de5d2d",
      "#3d7eb8",
      "#ca0d45",
      "#f7f285",
      "#0060af",
      "#852848",
      "#f9f7bc",
      "#004889",
      "#7e0d3c",
      "#e4d64c",
    ],
  },
};
