import styled from 'styled-components';

const RegisterContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 88vh; */
  width: 100vw;
  margin-top: 3.733vw;
`;

export default RegisterContainer;