import styled from 'styled-components';

const FirstStripText = styled.h2`
  display: flex;
  align-self: flex-end;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 3vw;
  color: #FFFFFF;
  background-color: #000000;
`;

export default FirstStripText;