import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    background-color: white;
    color-scheme: light only;
  }

  svg {
    background-color: transparent;
  }

  span {
    background-color: transparent;
  }

  .swal2-success-ring {
    background-color: transparent;
  }

  .swal2-container {
    z-index: 9999999999991;
  }

  .swal2-icon-content {
    background-color: transparent;
  }

  .swal2-close:focus {
    outline: none;
    box-shadow: none;
  }
`;