export const userFormDataInitialValue = {
  zipCode: {
    inputName: "CEP",
    inputValue: ""
  },
  city: {
    inputName: "Cidade",
    inputValue: ""
  },
  state: {
    inputName: "Estado",
    inputValue: ""
  },
  phone: {
    inputName: "Celular",
    inputValue: ""
  },
  day: {
    inputName: "Dia do nascimento",
    inputValue: ""
  },
  month: {
    inputName: "Mês do nascimento",
    inputValue: ""
  },
  year: {
    inputName: "Ano do nascimento",
    inputValue: ""
  },
  colorChartId: {
    inputName: "Cartela de cores",
    inputValue: ""
  },
  biotypeId: {
    inputName: "Biotipo",
    inputValue: ""
  },
  primaryStyleId: {
    inputName: "Estilo",
    inputValue: ""
  },
  signId: {
    inputName: "Signo",
    inputValue: ""
  },
  upSize: {
    inputName: "Parte de cima",
    inputValue: ""
  },
  downSize: {
    inputName: "Parte de baixo",
    inputValue: ""
  },
  footSize: {
    inputName: "Sapato",
    inputValue: ""
  },
  customerConsultant: {
    inputValue: 0
  }
}