import PropTypes from "prop-types";
import React, { useState } from "react";
import Context from "./Context";
import { allColorChart } from "../data/colorChartHexa";
import { consultantFormDataInitialValue } from "../data/consultantData/consultantFormDataInitialValue";
import { userFormDataInitialValue } from "../data/userData/userFormDataInitialValue";

export default function Provider({ children }) {
  const [postId, setPostId] = useState(0);
  const [imgUrl, setImgUrl] = useState("");
  const [file, setFile] = useState("");
  const [insideOrigin, setInsideOrigin] = useState(false);
  const [foldersData, setFoldersData] = useState([]);
  const [profileImg, setProfileImg] = useState("");
  const [totalLooks, setTotalLooks] = useState([]);
  const [username, setUsername] = useState("");
  const [consultantClientData, setConsultantClientData] = useState({});
  const [onlineCheckoutService, setCheckoutOnlineService] = useState({
    originalPrice: "",
    currentPrice: "",
    serviceImage: "",
    serviceName: "",
  });
  const [consultantOnlineService, setConsultantOnlineService] = useState({});
  const [buttonSelected, setButtonSelected] = useState("looks");
  const [consultantLooks, setConsultantLooks] = useState([]);
  const [dossieTitle, setDossieTitle] = useState("");
  const [allDossies, setAllDossies] = useState("");
  const [refreshOnlyUserLooksPage, setRefreshOnlyUserLooksPage] =
    useState(false);
  const [updateProfileImg, setUpdateProfileImg] = useState(false);
  const [planData, setPlanData] = useState({
    planId: "",
    planImage: "",
    planName: "",
    originalPrice: "",
    price: "",
  });
  const [timeEnd, setTimeEnd] = useState(false);
  const [colorChartHexa, setColorChartHexa] = useState(allColorChart);
  const [dataProfile, setDataProfile] = useState();
  const [userEmailDossie, setUserEmailDossie] = useState("");
  const [userProfileFormData, setUserProfileFormData] = useState(
    userFormDataInitialValue
  );
  const [consultantProfileFormData, setConsultantProfileFormData] = useState(
    consultantFormDataInitialValue
  );
  const [currentClimate, setCurrentClimate] = useState({});
  const [setIconClimate, iconClimate] = useState({});
  const [updateConsultantProfileImg, setUpdateConsultantProfileImg] =
    useState(false);
  const [dataConsultantProfile, setDataConsultantProfile] = useState();
  const [consultantProfileImg, setConsultantProfileImg] = useState("");
  const [currentTime, setCurrentTime] = useState({
    maxTemp: 0,
    minTemp: 0,
    humidity: 0,
  });
  const [cityName, setCityName] = useState("");
  const [consultantName, setConsultantName] = useState("");
  const [pathBanner, setPathBanner] = useState(false);
  const [insideOriginConsultant, setInsideOriginConsultant] = useState(false);
  const [recipientStatement, setRecipientStatement] = useState();
  const [subscriptionServiceId, setSubscriptionServiceId] = useState(null)

  const contextValue = {
    postId,
    setPostId,
    imgUrl,
    setImgUrl,
    file,
    setFile,
    insideOrigin,
    setInsideOrigin,
    foldersData,
    setFoldersData,
    profileImg,
    setProfileImg,
    allDossies,
    setAllDossies,
    totalLooks,
    setTotalLooks,
    username,
    setUsername,
    dossieTitle,
    setDossieTitle,
    userEmailDossie,
    setUserEmailDossie,
    consultantClientData,
    setConsultantClientData,
    onlineCheckoutService,
    setCheckoutOnlineService,
    consultantOnlineService,
    setConsultantOnlineService,
    refreshOnlyUserLooksPage,
    setRefreshOnlyUserLooksPage,
    buttonSelected,
    setButtonSelected,
    consultantLooks,
    setConsultantLooks,
    updateProfileImg,
    setUpdateProfileImg,
    planData,
    setPlanData,
    timeEnd,
    setTimeEnd,
    colorChartHexa,
    setColorChartHexa,
    dataProfile,
    setDataProfile,
    consultantProfileFormData,
    setConsultantProfileFormData,
    currentClimate,
    setCurrentClimate,
    setIconClimate,
    iconClimate,
    updateConsultantProfileImg,
    setUpdateConsultantProfileImg,
    dataConsultantProfile,
    setDataConsultantProfile,
    consultantProfileImg,
    setConsultantProfileImg,
    currentTime,
    setCurrentTime,
    cityName,
    setCityName,
    profileImg,
    setProfileImg,
    consultantName,
    setConsultantName,
    userProfileFormData,
    setUserProfileFormData,
    pathBanner,
    setPathBanner,
    insideOriginConsultant,
    setInsideOriginConsultant,
    recipientStatement,
    setRecipientStatement,
    subscriptionServiceId,
    setSubscriptionServiceId
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
