import styled from "styled-components";

export const XIcon = styled.img`
  width: ${(props) => props.width || '3.989vw'};
  height: ${(props) => props.height || '3.989vw'};
  background-color: transparent;
  margin: ${(props) => props.margin || '1.333vw 5.333vw 0 0'};
  position: ${(props) => props.positionIcon};
  flex: none;
  display: ${(props) => props.display || 'flex'};
  visibility: ${(props) => props.visibility}
`;