import styled from 'styled-components';

const SafeRegisterText = styled.span`
  font-family: 'Libre Franklin', sans-serif;
  font-size: 3.2vw;
  font-weight: 400;
  background-color: #FAFAFA;
  padding-left: 1vw;
`;

export default SafeRegisterText;