import styled from "styled-components";

const Button = styled.button`
  font-family: 'Outfit', sans-serif;
  font-size: ${(props) => props.fontSize || '0.75rem'};
  font-weight: 700;
  display: ${(props) => props.displayButton || 'flex'};
  align-items: center;
  justify-content: ${(props) => props.justify || 'space-evenly'};
  color: ${(props) => props.color};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) => props.border || '1px solid #000000'};
  border-radius: ${(props) => props.borderRadius || '2.666vw'};
  margin-bottom: ${(props) => props.bottomMobile};
  margin-top: ${(props) => props.top};
  background-color: ${(props) => props.bgColor || '#000000'};
  margin-left: ${(props) => props.marginLeft};
  padding: ${(props) => props.padding};
  cursor: pointer;
  flex: none;

  @media (max-height: 667px) {
    margin-bottom: ${(props) => props.bottomSE};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #efefef;
    border: none;
  }

  @media (min-width: 800px) {
    margin-bottom: ${(props) => props.bottomDesktop};
  }
`
export default Button;