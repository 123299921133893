import styled from 'styled-components';

const InputTextIcon = styled.img`
  position: ${(props) => props.position || 'absolute'};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: transparent;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  z-index: ${(props) => props.zIndex};
`;

export default InputTextIcon;
