import React from "react";

import YouTube from "react-youtube";

import {
  ButtonGoToApp,
  ContainerImage,
  ContainerLandingPage,
  ContainerToPosition,
  ImageEighthStrip,
  ImageFifthStrip,
  ImageFirstStrip,
  ImageFooter,
  ImageFourthStrip,
  ImageSecondStrip,
  ImageSeventhStrip,
  ImageSixthStrip,
  ImageThirdStrip,
} from "./style";

import imageFirstStripSVG from "../../assets/images/landing-page-desktop/image-first-strip.svg";
import imageSecondStripSVG from "../../assets/images/landing-page-desktop/image-second-strip.svg";
import imageThirdStripSVG from "../../assets/images/landing-page-desktop/image-third-strip.svg";
import imageFourthStripSVG from "../../assets/images/landing-page-desktop/image-fourth-strip.svg";
import imageFifthStripSVG from "../../assets/images/landing-page-desktop/image-fifth-strip.svg";
import imageSixthStripSVG from "../../assets/images/landing-page-desktop/image-sixth-strip.svg";
import imageSeventhStripSVG from "../../assets/images/landing-page-desktop/image-seventh-strip.svg";
import imageEighthStripSVG from "../../assets/images/landing-page-desktop/image-eighth-strip.svg";
import imageFooterSVG from "../../assets/images/landing-page-desktop/image-footer.svg";

export default function AddAppDesktop() {
  return (
    <ContainerLandingPage>
      <ContainerImage colorBg="#D9D9D9">
        <ImageFirstStrip
          src={imageFirstStripSVG}
          alt="imagem de uma mulher com celular na mão"
        />
        <ButtonGoToApp
          topMargin="601px"
          rightMargin="790px"
          href="https://apps.apple.com/br/app/resolva-meu-look/id1575252378"
        />
        <ButtonGoToApp
          topMargin="601px"
          rightMargin="460px"
          href="https://play.google.com/store/apps/details?id=com.guilu.appresolvaclub"
        />
      </ContainerImage>
      <ImageSecondStrip
        src={imageSecondStripSVG}
        alt="icones ilustrativos das funcionalidades do app"
      />
      <ContainerImage colorBg="#DEDCDD">
        <ImageThirdStrip
          src={imageThirdStripSVG}
          alt="imagem de uma mulher olhando suas roupas na arara"
        />
        <YouTube
          videoId="KHz4j_lZWao"
          opts={{
            width: "640px",
            height: "363px",
          }}
          style={{
            position: "absolute",
            marginLeft: "450px",
            marginTop: "125px",
            background: "transparent",
          }}
        />
      </ContainerImage>
      <ImageFourthStrip
        src={imageFourthStripSVG}
        alt="Imagem com smartphones mostrando as telas do app"
      />
      <ContainerImage colorBg="#D0B8A0">
        <ImageFifthStrip
          src={imageFifthStripSVG}
          alt="Imagem de uma mulher se maquiando"
        />
      </ContainerImage>
      <ImageSixthStrip
        src={imageSixthStripSVG}
        alt="Icones representando as funcionalidades do app"
      />
      <ContainerImage colorBg="#D5838F">
        <ImageSeventhStrip
          src={imageSeventhStripSVG}
          alt="Imagem representando o raio x do closet do app"
        />
      </ContainerImage>
      <ImageEighthStrip
        src={imageEighthStripSVG}
        alt="Imagens de peças de roupas"
      />
      <ContainerImage colorBg="#4C4773">
        <ImageFooter
          src={imageFooterSVG}
          alt="Icone do resolva em uma faixa roxa"
        />
        <ButtonGoToApp
          topMargin="68px"
          rightMargin="-670px"
          href="https://apps.apple.com/br/app/resolva-meu-look/id1575252378"
        />
        <ButtonGoToApp
          topMargin="68px"
          rightMargin="-1000px"
          href="https://play.google.com/store/apps/details?id=com.guilu.appresolvaclub"
        />
      </ContainerImage>
    </ContainerLandingPage>
  );
}
