import styled from 'styled-components';

export const InputTextMessage = styled.span`
  font-family: 'Libre Franklin';
  font-size: 1.6vh;
  font-weight: 400;
  display: ${(props) => props.display};
  color: ${(props) => props.color};
  background-color: transparent;
  margin-top: 1vw;
`;