import styled from 'styled-components';

const RegisterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #EFEFEF;
  border-radius: 6.666vw;
  background-color: #FAFAFA;
  height: ${(props) => props.height};
  width: 93.6vw;
`;

export default RegisterBox;