import styled from 'styled-components';

export const GeneralFirstStrip = styled.div`
  background-color: #000000;
  width: 100%;
  height: ${(props) => props.height || '26px'};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 14;
`;