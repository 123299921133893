import React from "react";
import "./App.css";
import Provider from "./contexts/Provider";
import AppRoutes from "./routes";
import { GlobalStyle } from "./Components/GlobalStyle";
// import ModalAddIphone from "./Components/ModalAddIphone";
import dotenv from "dotenv";
dotenv.config();

// let deferredPrompt;

export default function App() {
  // const modalAddAndroidRef = useRef();
  // const buttonAddToHomeScreen = useRef();
  // const modalAddIphoneRef = useRef();
  // const xIcon = useRef();

  // const defPromptRef = useRef(deferredPrompt)

  // if ("serviceWorker" in navigator) {
  //   navigator.serviceWorker
  //     .register("./serviceWorker.js")
  //     .then(function () {
  //       console.log("service worker registered");
  //     })
  //     .catch(function () {
  //       console.warn("service worker failed");
  //     });
  // }

  // const hideModal = () => {
  //   modalAddAndroidRef.current.style.display = "none";
  // };

  // const addToHomeScreen = () => {
  //   modalAddAndroidRef.current.style.display = "none";

  //   defPromptRef.current.prompt();
  //   defPromptRef.current.userChoice.then((choiceResult) => {
  //     if (choiceResult.outcome === "accepted") {
  //       console.log("User ACCEPTEDDD");
  //     } else {
  //       console.log("User DISMISSEDDD");
  //     }
  //     defPromptRef.current = null;
  //   });
  // };

  // useEffect(() => {
  //   modalAddAndroidRef.current.style.display = "none";
  //   modalAddIphoneRef.current.style.display = 'none';

  //   window.addEventListener("beforeinstallprompt", (e) => {
  //     // Impede que o mini-infobar apareça em mobile
  //     e.preventDefault();
  //     // Guarda evento para que possa ser disparado depois.
  //     defPromptRef.current = e;
  //     modalAddAndroidRef.current.style.display = "flex";
  //   });

  //   fnBrowserDetect();
  // })

  // function fnBrowserDetect() {
  //   let userAgent = navigator.userAgent;

  //   if (
  //     userAgent.match(/safari/i) &&
  //     "standalone" in window.navigator && !window.navigator.standalone
  //   ) {
  //     modalAddIphoneRef.current.style.display = "flex";
  //   }
  // }

  // const update = () => {
  //   window.location.reload()
  // }

  return (
    <Provider>
      {/* <ModalAddIphone reference={modalAddIphoneRef}/>
      <div className="background-modal" ref={modalAddAndroidRef}>
        <div className="modal">
          <img className="logo-icon" src="icon-512x512.png" alt="icone Resolva"/>
          <img ref={xIcon} className="x-icon" src="xIcon.svg" alt="icone de X para fechar" onClick={hideModal} />
          <span className="text">
            Transforme o Resolva em um app e tenha uma experiência incrível!
          </span>
          <button ref={buttonAddToHomeScreen} className="buttonAdd" onClick={addToHomeScreen}>
            ADICIONAR AGORA!
          </button>
        </div>
      </div> */}
      {/* <button
        style={{ position: 'absolute', width: '10vw', height: '10vw' }}
        onClick={update}>ATUALIZAR</button> */}
      <GlobalStyle />
      <AppRoutes />
    </Provider>
  );
}
