import styled from 'styled-components';

const InputTextContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  align-items: ${(props) => props.align || 'flex-start'};
  position: relative;
  background-color: ${(props) => props.bgColor};
  padding-top: ${(props) => props.top};
  justify-content: center;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  opacity: ${(props) => props.opacity}
`;

export default InputTextContainer;