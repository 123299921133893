import styled from 'styled-components';

export const ContainerQrCode = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PixButtonCopy = styled.button`
  border: none;
  background-color: #f4f2f2;
  padding: 5.1vw 10.2vw 5.1vw 5.1vw;
  position: relative;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 3.5vw;
  color: #c4c3c3;
  border-radius: 2.666vw;
  cursor: pointer;
  margin: 1rem 0 2rem 0;
  width: 89.333vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  @media (min-width: 800px) {
    width: 26.3vw;
    font-size: 0.9vw;
    padding: 20px 0;
    border-radius: 0.625vw;
  }

  span {
    color: black;
    font-weight: bold;
    text-align: center;
    margin-left: 20px;
  }
`

export const CopyIcon = styled.img`
  background-color: transparent;
  position: absolute;
  width: 20px;
  right: 9px;
  top: 18px;
`

export const PixButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    width: 89.333vw;
    text-align: center;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 3.468vw;

    @media (min-width: 800px) {
    width:26.8vw;
    font-size: 0.813vw;
    } 
  }
  
  @media (min-width: 800px) {
    width:26.8vw;
  }
`;

export const ContainerInformationLeft = styled.div`
  margin-bottom: 13.8vw;
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.direction};
  @media (min-width: 800px) {
    display: flex;
    width: 26.8vw;
    flex-direction: column;
    align-items: ${(props) => props.align};
    margin-right: 2.5vw;
    margin-left: 20.7vw;
  }
  `;

export const StyleLoadingGif = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  visibility: ${(props) => props.visibility};
  align-self: center;
  background-color: ${(props) => props.bg};
`;

export const ContainerEachItemSelectCard = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Libre Franklin', sans-serif;
  background-color: transparent;
  font-size: 3.468vw;
  color: #ffffff;
  margin-right: 3vw;
  cursor: pointer;
  @media (min-width: 800px) {
      font-size: 0.813vw;
    }

  p {
    font-family: 'Libre Franklin', sans-serif;
    background-color: transparent;
    font-size: 4.266vw;
    color: #ffffff;
    text-indent: 2vw;
    flex: none;
    cursor: default;
    margin: 0 !important;

    @media (min-width: 800px) {
      text-indent: 10px;
      font-size: 1vw;
    }
  }
`;

export const ContainerSelectCardOrModify = styled.div`
  display: flex;
  width: 89.333vw;
  font-family: 'Libre Franklin', sans-serif;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  border-radius: 2.666vw;
  text-indent: 2vw;
  font-size: 3.733vw;
  height: 13.333vw;
  margin-bottom: 6vw;
  @media (min-width: 800px) {
    width: 26.8vw;
    height: 3vw;
    margin-bottom: 1vw;
    height: 3.1vw;
    font-size: 0.875vw;
    border-radius: 0.625vw;
  }
`;

export const IconsPurchase = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-left: ${(props) => props.leftMobile};
  background-color: transparent;
  @media (min-width: 800px) {
    width: ${(props) => props.mediaWidth};
    height: ${(props) => props.mediaHeight};
    margin-left: ${(props) => props.left};
  }
`;

export const PaymentText = styled.form`
  display: flex;
  flex-direction: column;
  font-size: 5.866vw;
  font-weight: 500;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Libre Franklin', sans-serif;
  align-self: flex-start;
  padding-left: 15px;
  padding-bottom: 0.8vh;
  @media (min-width: 800px) {
    font-size: 1.375vw;
  }
`