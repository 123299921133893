import React from "react";

import YouTube from "react-youtube";

import {
  ContainerLandingPage,
  ButtonStore,
  ContainerButtons,
  ImageFirstStrip,
  ImageSecondStrip,
  ImageThirdStrip,
  ImageFifthStrip,
  ImageSixthStrip,
  ImageSeventhStrip,
  ImageEighthStrip,
  ImageNinthStrip,
  ImageTenthStrip,
  ContainerStrip,
} from "./style";

import firstStripPNG from "../../assets/images/mobile-icons/first-strip.png";
import secondStripPNG from "../../assets/images/mobile-icons/second-strip.png";
import thirdStripPNG from "../../assets/images/mobile-icons/third-strip.png";
import fifthStripPNG from "../../assets/images/mobile-icons/fifth-strip.png";
import sixthStripPNG from "../../assets/images/mobile-icons/sixth-strip.png";
import seventhStripPNG from "../../assets/images/mobile-icons/seventh-strip.png";
import eighthStripPNG from "../../assets/images/mobile-icons/eight-strip.png";
import ninthStripPNG from "../../assets/images/mobile-icons/ninth-strip.png";
import tenthStripPNG from "../../assets/images/mobile-icons/tenth-strip.png";
import appleButtonPNG from "../../assets/images/add-desktop-icons/apple-button.png";
import googleButtonPNG from "../../assets/images/add-desktop-icons/google-button.png";

function MobileAddApp() {
  return (
    <ContainerLandingPage>
      <ContainerStrip>
        <ImageFirstStrip
          src={firstStripPNG}
          alt="Página de apresentação do app resolva com uma mulher rindo e segurando o celular e logo abaixo fotos do app"
        />
        <ContainerButtons top="109.7vw" left="23.7vw">
          <a href="https://apps.apple.com/br/app/resolva-meu-look/id1575252378">
            <ButtonStore
              src={appleButtonPNG}
              alt="App store logo"
              role="button"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.guilu.appresolvaclub">
            <ButtonStore
              src={googleButtonPNG}
              alt="Play store logo"
              role="button"
            />
          </a>
        </ContainerButtons>
      </ContainerStrip>
      <ImageSecondStrip
        src={secondStripPNG}
        alt="Seção apresentando algumas ferramentas do app como: Minhas Roupas, Meus Looks, Calendário e Combinômetro"
      />
      <ImageThirdStrip
        src={thirdStripPNG}
        alt="Mulher escolhendo roupas do guarda-roupa"
      />
      <YouTube
        videoId="KHz4j_lZWao"
        opts={{
          width: window.screen.width * 0.97,
          height: window.screen.width * 0.565,
        }}
        style={{
          background: "black",
          padding: "3vw 3vw 2vw 3vw",
        }}
      />
      <ImageFifthStrip
        src={fifthStripPNG}
        alt="Fotos de celulares mostrando o aplicativo resolva e as vantages que ele oferece"
      />
      <ImageSixthStrip
        src={sixthStripPNG}
        alt="Mulher se maquiando e logo abaixo indicações de makes oferecidas pelo App resolva"
      />
      <ImageSeventhStrip
        src={seventhStripPNG}
        alt="Ícones com mais funcionalidades do App resolva, como: Circulo cromático, Cartela de cor, Shopping list e Achadinhos"
      />
      <ImageEighthStrip
        src={eighthStripPNG}
        alt="Mulher sorrindo e logo abaixo dela gráficos representando estátisticas sobre o seu guarda-roupa que está no APP resolva"
      />
      <ImageNinthStrip
        src={ninthStripPNG}
        alt="Gráfico representando a saúde do closet da cliente e o preço das roupas"
      />
      <ContainerStrip>
        <ImageTenthStrip
          src={tenthStripPNG}
          alt="Logo do resolva e solicitação para baixar o APP clicando em um dos botões abaixo"
        />
        <ContainerButtons top="58.24vw" left="23.7vw">
          <a href="https://apps.apple.com/br/app/resolva-meu-look/id1575252378">
            <ButtonStore
              src={appleButtonPNG}
              alt="App store logo"
              role="button"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.guilu.appresolvaclub">
            <ButtonStore
              src={googleButtonPNG}
              alt="Play store logo"
              role="button"
            />
          </a>
        </ContainerButtons>
      </ContainerStrip>
    </ContainerLandingPage>
  );
}

export default MobileAddApp;
