import styled from 'styled-components';

const RegisterText = styled.p`
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 400;
  font-size: 3.733vw;
  padding-top: 1.333vw;
  padding-bottom: ${(props) => props.bottom || '5.6vw'};
  text-align: center;
  width: ${(props) => props.width || '77.33vw'};
  line-height: 1.3;
  background-color: #FAFAFA;
`;

export default RegisterText;