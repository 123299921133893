import styled from "styled-components";

export const ContainerLandingPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  flex: none;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const ContainerStrip = styled.div`
  position: relative;
`;

export const ImageFirstStrip = styled.img`
  width: 100vw;
  height: 242.13vw;
`;

export const ContainerButtons = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  width: 52.6vw;

  a {
    margin: 0;
    background-color: transparent;
  }
`;

export const ButtonStore = styled.img`
  width: 24.4vw;
  background-color: transparent;
  height: 7.4vw;
`;

export const ImageSecondStrip = styled.img`
  width: 100vw;
  height: 142.6vw;
`;

export const ImageThirdStrip = styled.img`
  width: 100vw;
  height: 73.3vw;
`;

export const ImageFourthStrip = styled.div`
  width: 100vw;
  height: 56.5vw;
  background-color: black;
`;

export const ImageFifthStrip = styled.img`
  width: 100vw;
  height: 263.7vw;
`;

export const ImageSixthStrip = styled.img`
  width: 100vw;
  height: 296.5vw;
`;

export const ImageSeventhStrip = styled.img`
  width: 100vw;
  height: 169vw;
`;

export const ImageEighthStrip = styled.img`
  width: 100vw;
  height: 213.3vw;
`;

export const ImageNinthStrip = styled.img`
  width: 100vw;
  height: 247.7vw;
`;

export const ImageTenthStrip = styled.img`
  width: 100vw;
  height: 77.3vw;
`;
